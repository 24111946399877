<template>
  <div>
     <div class="container">
      <Menu_loja />
    </div>
    <div class="mt-15 mb-8">
      <div class="container-fluid h-100">
        <div class="row justify-content-center align-items-center h-100">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <v-card elevation="8">
              <div class="card-body p-4">
                <div class="pt-2 form-login">
                  <img src="@/assets/images/logo.png">
                  <h4 class="font">Verifique seu email para mudar senha.</h4>
                  <div class="form-group mb-3">
                    <v-text-field
                      color="black"
                      v-model="email"
                      type="email"
                      label="Email"
                      placeholder="Digite seu email"
                      id="EmailUsuario"
                      required
                      clearable
                    ></v-text-field>
                  </div>

                  <div class="form-group mb-0 text-center">
                    <v-btn
                      class="appBG-primary"
                      @click.prevent="verificarEmail"
                    >
                      <span class="appColor-white" v-if="!loading">
                        Confirmar
                      </span>
                      <v-progress-circular
                        v-else
                        indeterminate
                        color="white"
                      ></v-progress-circular>
                    </v-btn>
                  </div>

                  <div class="form-group mt-8 mb-0 text-center">
                    <v-btn text @click.prevent="voltar">
                      <span class="appColor-primary">
                        Voltar para a tela de login
                      </span>
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UsuarioService from "@/services/usuario_service";
import Mixin from "@/mixins/vuex_mixin.js";
import Menu_loja from "../components/template/header/navbar/menu_loja.vue";


export default {
  mixins: [Mixin],
  components: {
    Menu_loja,
  },
  data: () => ({
    loading: false,
    email: "",
  }),
  methods: {
    voltar() {
      this.$router.replace("login");
    },
    verificarEmail() {
      this.loading = true;
      const usuarioService = new UsuarioService();
      usuarioService.esqueciSenha(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              // this.$_ACTIONS_showSnackbarMessage({
              //   message: body.message,
              //   color: "sucess",
              // });
              this.$toast.open({
                position: 'top-right',
                message: 'Uma nova senha foi enviada para o seu email!',
                type: 'success',
                duration: 4000
                // all of other options may go here
              });
            }
            else {
              // this.$_ACTIONS_showSnackbarMessage({
              //   message: body.message || "Email não encontrado",
              //   color: "warning",
              // });
              this.$toast.open({
                position: 'top-right',
                message: body.message || 'Email não encontrado',
                type: 'error',
                duration: 4000
                // all of other options may go here
              });
            }
          },
          onError: (error) => {
            console.error(error);
            this.$_ACTIONS_showSnackbarMessage({
              message: this.$global.messages.internalServerError,
              color: "error",
            });
          },
          onEnd: () => {
            this.loading = false;
          },
        },
        { email: this.email }
      );
    },
  },
};
</script>