<template>
  <div id="app">
    <v-app>
      <!-- <div id="nav">
        <router-link to="/">Home</router-link> |
        <router-link to="/about">About</router-link>
      </div> -->
      <router-view />
      <div>
        <snackbar />
      </div>
    </v-app>
  </div>
</template>

<script>
import Snackbar from "@/components/widgets/snackbar.vue";

export default {
  components: {
    Snackbar,
  },
};

import Vue from "vue";
import VueCarousel from "vue-carousel";
Vue.use(VueCarousel);
</script>

<style>
/* App css */
@import "assets/css/bootstrap.min.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

/* #nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
} */

.sub {
  font-size: 21px;
}
.btncall,
.btncall:hover {
  border: 2px solid #2b3a8f;
  font-weight: 500;
  font-size: 19px !important;
  padding: 1rem 5rem 1rem 5rem !important;
  margin: 2rem 0rem 4rem 0rem !important;
  border-radius: 15px;
  color: #2b3a8f !important;
}
.btncallwhite,
.btncallwhite:hover {
  border: 2px solid #fff;
  font-weight: 500;
  font-size: 19px;
  padding: 1rem 5rem 1rem 5rem;
  margin: 2rem 0rem 4rem 0rem;
  color: #fff !important;
  border-radius: 15px;
}
.senha {
  color: #14457c !important;
  font-family: Poppins !important;
  font-size: 20px;
}
.benef {
  margin-right: 1rem;
  padding: 3% !important;
}
.taxa {
  font-family: Poppins;
}
.conta {
  font-size: 20px;
}
.appColor-primary,
.appColor-primary--text {
  color: #14457c !important;
}
.titles {
  font-family: "Roboto";
  font-size: 38px;
  line-height: 49px;
}
.appColor-secondary {
  color: #282828;
}
.appColor-blue,
.appColor-blue a {
  color: #3178ac !important;
}
.bgtrans,
.bgtrans:hover {
  background-color: transparent;
}

.appColor-white {
  color: #fff !important;
}
.appColor-gray {
  color: #9c9c9c !important;
}

.footerColor {
  color: #dcdcdc !important;
}
.tit {
  font-family: "Roboto";
  font-size: 59px;
  font-weight: 900;
  line-height: 49px;
  color: #95c1e2;
}
.bg-meio {
  background-color: #3178ac;
}
.blockhead {
  padding: 2rem 0rem 2rem 8rem;
  margin: 7rem 0rem 3rem -1rem;
}
.linkhead {
  padding: 0rem 0rem 3rem 8rem;
  font-size: 18px;
}
.linkhead a {
  color: #fff !important;
}
.appBG-primary {
  background-color: #7bb2db !important;
}
.appBG-blue {
  background-color: #3178ac !important;
}
.appBG-bluedark {
  background-color: #14457c !important;
}

.appBG-secondary {
  background-color: #959595;
}

.appBG-white {
  background-color: #fff !important;
}

.footerBG {
  background-image: linear-gradient(
      rgba(49, 120, 172, 0.4),
      rgba(49, 120, 172, 0.4)
    ),
    url("assets/images/form.png");
  background-size: inherit;
  background-position-y: center;
  background-position-x: center;
}
.bg-servicos {
  background-image: url("assets/images/bg-servicos.png");
}
.bg-sobre {
  background-image: url("assets/images/bg-sobre.png");
}
.bg-detalhes {
  background-image: url("assets/images/bg-detalhes.png");
}
.app-Border-primary {
  border: 1px #2b3a8f solid;
}

.app-font-size-icon {
  font-size: 0.7rem !important;
}

.margintop-menu {
  margin-top: 10rem;
}

p {
  margin-bottom: 0 !important;
}
.font {
  font-family: "Roboto";
}
.bloco {
  display: flex;
  flex-wrap: wrap;
}
.bloco .col-5,
.bloco .col-7 {
  padding: 0px;
}
.btncallblue {
  border: 3px solid #3178ac;
  background-color: transparent !important;
  font-weight: 500;
  padding: 1rem 2rem 1rem 2rem !important;
  color: #3178ac !important;
  text-transform: capitalize;
  border-radius: 1px;
  font-weight: 900;
  font-size: 17px !important;
}
#tabs-calendarioVacinal .v-tabs-bar,
#tabs-calendarioVacinal .v-tabs-slider.transparent {
  background-color: transparent !important;
}

.termo-politica label {
  margin-bottom: 0;
}

.termo-politica .links {
  text-decoration: underline;
  color: rgba(0, 0, 0, 0.6);
}
.bannercall .txt {
  font-family: "Permanent Marker";
  font-size: 38px;
  line-height: 49px;
}
.somb {
  box-shadow: 0px 10px 16px #00000029 !important;
  border-radius: 16px !important;
}
.cardb {
  border: 1px solid #707070;
  border-radius: 13px;
  opacity: 1;
}
.list-group-item {
  border: 2px solid #2b3a8f;
  color: #2b3a8f;
  font-weight: 600;
  font-family: Poppins;
}
.titulologin {
  font-weight: 600;
  font-size: 17px;
}
.diaria {
  margin-top: 2rem;
}
.menu {
  margin-top: -13rem;
}
.block-select .block {
  justify-content: space-evenly;
  display: flex;
  color: #fff;
  text-align: center;
}
.btnaccount {
  margin-right: 1rem;
}
@media (max-width: 600px) {
  .modal-carrinho {
    width: 87% !important;
  }

  .blockhead {
    padding: 2rem 0rem 2rem 2rem;
  }
  .linkhead {
    padding: 0rem 0rem 3rem 1rem;
  }
  .bg-servicos {
    background-position-x: -19rem;
  }
  .bg-sobre {
    background-position-x: -53rem;
  }
  .bg-detalhes {
    background-position-x: -46rem;
  }
  .form {
    margin: -4rem 0rem 2rem 0rem !important;
  }
  .v-application .d-flex,
  .block-select .block {
    display: block !important;
  }
  .footerBG {
    background-size: cover;
  }
  .block-select {
    padding: 1rem !important;
  }
  .seta[data-v-589bdd23]::after {
    display: none !important;
  }

  .imgfooter {
    display: none;
  }
  .bloco {
    display: block !important;
  }
  .banner-principal {
    height: fit-content !important;
    margin-top: 0px !important;
  }
  .margintop-menu {
    margin-top: 3em;
  }
  .titles {
    font-size: 19px;
  }
  .col-9,
  .col-3,
  .col-7,
  .col-6,
  .col-8,
  .col-4,
  .col-5 {
    max-width: 100% !important;
  }
  .acess .col-5 img {
    display: none;
  }
  .blockacess {
    height: 33rem !important;
  }
  .btnaccount {
    margin-bottom: 1rem;
  }
}
</style>
