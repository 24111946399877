<template>
  <div>
    <template>
      <v-sheet class="container mx-auto" elevation="0">
        <v-slide-group show-arrows>
          <v-slide-item v-for="(medico, n) in medicos" :key="n">
            <v-card class="ma-4 pa-4" max-width="320px" elevation="4">
              <v-img :src="medico.foto"></v-img>
              <h3 class="text-left">{{ medico.nome }}</h3>
              <h4 class="text-left">{{ medico.crm }}</h4>
              <ul>
                <li
                  class="text-left"
                  v-for="(especilidade, i) in medico.especialidades"
                  :key="i"
                >
                  {{ especilidade }}
                </li>
              </ul>
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </v-sheet>
    </template>
  </div>
</template>

<script>
export default {
    data: () => ({
    medicos: [
      {
        foto: require("@/assets/images/corpo-clinico/roberto.jpg"),
        nome: "Roberto da Justa",
        crm: "DIRETOR TÉCNICO - CRM 5976",
        especialidades: [
          "Médico Infectologista.",
          "Membro da Sociedade Brasileira de Infectologia (SBI) e da Sociedade Brasileira de Imunizações (SBIm).",
          "Mestrado e Doutorado em Ciências Médicas pela Faculdade de Medicina de Ribeirão Preto, Universidade de São Paulo (FMRP/USP).",
          "Graduação em Medicina pela Faculdade de Medicina da Universidade Federal do Ceará (FM/UFC).",
        ],
      },
      {
        foto: require("@/assets/images/corpo-clinico/alexandre.jpg"),
        nome: "Alexandre de Andrade Cavalcanti",
        crm: "CRM 7816",
        especialidades: [
          "Médico Geriatra.",
          "Membro da Sociedade Brasileira de Geriatria.",
          "Mestre em Saúde Pública pela Universidade Federal do Ceará.",
        ],
      },
      {
        foto: require("@/assets/images/corpo-clinico/eveline.jpg"),
        nome: "Eveline Campos Monteiro de Castro",
        crm: "CRM 3950",
        especialidades: [
          "Médica Pediatra.",
          "Membro da Sociedade Brasileira de Pediatria.",
          "Doutorado em Pediatria pela Universidade Federal de São Paulo.",
        ],
      },
      {
        foto: require("@/assets/images/corpo-clinico/francisco.jpg"),
        nome: "Francisco Monteiro de Castro Junior",
        crm: "CRM 2888",
        especialidades: [
          "Médico Cirurgião de Cabeça e Pescoço.",
          "Membro da Sociedade Brasileira de Cirurgia de Cabeça e Pescoço.",
          "Docente do Serviço de Cirurgia de Cabeça e Pescoço da Faculdade de Medicina – UFC.",
          "Mestrado em Cirurgia pela Universidade Federal Fluminense – RJ.",
        ],
      },
      {
        foto: require("@/assets/images/corpo-clinico/geraldo.jpg"),
        nome: "Geraldo Eduardo Pinheiro",
        crm: "CRM 4214",
        especialidades: [
          "Médico Urologista.",
          "Membro da Sociedade Brasileira de Urologia.",
        ],
      },
      {
        foto: require("@/assets/images/corpo-clinico/jose.jpg"),
        nome: "José Wilson Meireles da Trindade",
        crm: "DIRETOR CLÍNICO - CRM 2547",
        especialidades: [
          "Médico Cirurgião de Cabeça e Pescoço.",
          "Membro da Sociedade Brasileira de Cirurgia de Cabeça e Pescoço.",
        ],
      },
      {
        foto: require("@/assets/images/corpo-clinico/nathalia.jpg"),
        nome: "Nathalia Siqueira Robert de Castro",
        crm: "DIRETOR CLÍNICO - CRM 11.766",
        especialidades: [
          "Médica Alergologista e Imunologista.",
          "Título de Especialista pela Sociedade Brasileira de Alergia e Imunologia (ASBAI) – Membro da ASBAI.",
          "Especialização em Alergia e Imunologia Clínica pelo Hospital das Clínicas da Universidade de São Paulo (USP).",
          "Residência em Clínica Médica pelo Hospital Universitário Walter Cantídio da Universidade Federal do Ceará (UFC).",
          "Graduação em Medicina pela Faculdade de Medicina da Universidade Federal do Ceará (UFC).",
        ],
      },
      {
        foto: require("@/assets/images/corpo-clinico/tania.jpg"),
        nome: "Tânia Pinheiro",
        crm: "",
        especialidades: [
          "Enfermeira da São Carlos Vacinas.",
        ],
      },
    ],
  }),
}
</script>