<template>
  <div>
    <div class="container">
      <Menu_loja />
    </div>
    <div class="bg-sobre">
      <div class="col-6">
        <div class="appBG-primary blockhead">
          <p class="tit appColor-white">Sobre</p>
        </div>
        <p class="appColor-white linkhead font"><a href="/">Home</a> >> <a href="../sobre">Sobre</a></p>
      </div>
    </div>
    <div>
       <div class="d-flex">
      <div class="col-8 text-left block-select">
        <p class="tit">Conheça a StartFort</p>
        <p class="mt-5"><b>A STARFORT É ESPECIALISTA EM SEGUROS E SERVIÇOS EM DIFERENTES RAMOS<br></b>

A Starfort Corretora de Seguros LTDA, atua no mercado de segurador desde 2008, e vem desempenhando bem seu papel, por atender às necessidades de seus clientes, trabalhando com seguros e serviços em diferentes ramos como Automóvel, Vida, Previdência, Saúde e Odontológico, Condomínios, Empresarial, Riscos de Engenharia, Residencial, Fiança Locatícia e vários outros, buscando sempre trazer aos seus clientes toda tranquilidade, bem-estar e segurança.</p>
        <p class="mt-5"><b>VANTAGENS DE SER UM CLIENTE STARFORT</b>
        <ul style="list-style: none;">
          <li>Acumule saldo por indicação</li>
          <li>Preço diferenciado</li>
          <li>Cotações com rapidez e segurança</li>
          <li>Painel do cliente</li>
        </ul>


        </p>
        <!-- <h4>Certificações ou informações extras</h4>
        <div class="block mt-10">
          <div class="bg-meio">
            <p class="font">Comodidade para comprar 100% online</p>
          </div>
          <div class="bg-meio">
            <p class="font">Cotação com as maiores seguradoras do Brasil</p>
          </div>
          <div class="bg-meio">
            <p class="font">Assistência 24h para emergências</p>
          </div>
        </div> -->
        <v-btn class="mt-10 btn font appBG-blue appColor-white"
          href="#form"
          >Fale conosco</v-btn>
      </div>
      <div class="col-4">
        <img class="w-100" src="@/assets/images/sobredesc.png" />
      </div>
    </div>
    </div>
    <div class="mt-16">
      <footer-app />
    </div>
  </div>
</template>

<script>
import VuexUsuarioMixin from "@/mixins/vuex_usuario_mixin.js";
import VuexTokenMixin from "@/mixins/vuex_token_mixin.js";
import footerApp from "@/components/template/footer_app.vue";
import CarrinhoMixin from "@/mixins/carrinho_mixin.js";
import CompraService from "@/services/compras_service";
import Menu_loja from "../components/template/header/navbar/menu_loja.vue";

export default {
  components: {
    footerApp,
    Menu_loja,
  },

  mixins: [VuexUsuarioMixin, VuexTokenMixin, CarrinhoMixin],

  beforeMount() {
    this.atualizarCarrinhoCompra();
  },

  watch: {
    carrinhoProdutos: {
      handler: function (newcarrinho) {
        console.log(newcarrinho);
        this.atualizarTotalCompra(newcarrinho);
        this.atualizarquantidadeCompra(newcarrinho);
      },
      deep: true,
    },
  },

  methods: {
    logout() {
      this.$_ACTIONS_setUsuario({});
      this.$_ACTIONS_setToken("");
      this.$router.replace("login");
    },

    addToCartBatch(pedido) {
      pedido.pacotes.map((p) => {
        let carrinho = {
          titulo: p.pacote_nome,
          // valorTotal: parseFloat(p.preco) * p.quantidade,
          valorUnitario: parseFloat(p.preco),
          quantidade: parseInt(p.quantidade),
          id: p.pacote_id,
        };

        this.$_ACTIONS_carrinho(carrinho);
      });
    },
  },

  filters: {
    statusPagamento(status) {
      const stts = {
        waiting: "aguardando",
        paid: "pago",
        declined: "recusado",
      };

      return stts[status.toLowerCase()];
    },

    fillLeft: function (value = "", qtd = 0) {
      let v = value.toString();
      if (v.length < qtd) {
        return "0".repeat(qtd - v.length) + v;
      }

      return v;
    },

    formatData: function (data) {
      return data.substr(0, 10).split("-").reverse().join("/");
    },
  },

  mounted() {
    const compraService = new CompraService();
    compraService.getAll({
      onSucess: (status) => (body) => {
        if (status === 200) {
          this.pedidos = body.data.compras;
        }

        if (status === 400 || status === 500) {
          this.$router.push({ name: "login" });
        }
      },
      onError: () => {},
      onEnd: () => {
        this.loading = false;
      },
    });
  },

  // attachToCard(compra) {
  //   compra.pacotes.forEach(pacote => {
  //     //
  //   })
  // },

  data: () => ({
    pedidos: [],
    carrinhoProdutos: [],
    totalCompra: 0,
    quantidadeCompra: 0,
  }),
};
</script>


<style scoped>
.block-select {
  padding: 0rem 14rem 0rem 11rem;
}
.btn {
  padding: 10px !important;
}
.bg-meio {
  height: 7rem;
  max-width: 12rem !important;
  padding: 1rem 0.8rem 0rem 0.8rem;
  background-color: transparent !important;
  border: 1px solid black;
}
.bg-meio .font {
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
}
.nav-tabs .nav-link.active,
.nav-tabs:focus {
  border-color: transparent transparent #3178ac;
  border-bottom: 3px solid #3178ac;
  color: #3178ac;
  font-weight: 600;
}
.nav-link:hover,
.nav-tabs {
  border-color: transparent;
  color: #3178ac;
  font-weight: 600;
}
.titles {
  margin-left: 29px;
  font-size: 29px;
}
small {
  text-align: left;
  font-weight: 600;
  color: #757575;
}
.card-img-wrapper {
  max-width: 128px;
  border-radius: 5px;
}
.card-rel {
  display: flex;
}
h4,
p {
  text-align: left;
}

.detalhes-pedido {
  font-weight: bold;
  cursor: pointer;
  font-size: 14px;
}

.comprar-button {
  text-transform: unset;
  font-weight: bold;
}
</style>