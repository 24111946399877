<template>
  <v-card elevation="20" id="form" class="container">
    <div class="appColor-blue algform">
      <p v-if="isModal" class="txt formtxt mb-10" style="text-align: center;">Solicite sua cotação</p>
      <p v-else class="txt formtxt mb-10" style="text-align: center;">Mande uma mensagem</p>
      
      <div class="form-group">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            class="input-modal"
            color="black"
            v-model="dadosSolicitacaoAtendimento.nome"
            type="'text'"
            label="Nome"
            placeholder="Nome"
            :rules="validationNome"
            id="nome"
            required
            outlined
          >
          </v-text-field>
          <v-text-field
            color="black"
            v-model="dadosSolicitacaoAtendimento.email"
            type="'text'"
            label="E-mail"
            placeholder="exemplo@email.com.br"
            :rules="validationEmail"
            id="email"
            required
            outlined
          >
          </v-text-field>

          <v-text-field
            color="black"
            v-model="dadosSolicitacaoAtendimento.telefone"
            type="'telefone'"
            label="Telefone"
            placeholder="( )    -     "
            v-mask="'(##) #####-####'"
            :rules="validationTelefone"
            id="telefone"
            required
            outlined
          >
          </v-text-field>
          <v-text-field
            color="black"
            v-model="dadosSolicitacaoAtendimento.assunto"
            type="'text'"
            label="Assunto"
            placeholder="Assunto da mensagem"
            :rules="validationAssunto"
            id="assunto"
            required
            outlined
          >
          </v-text-field>
          
          <v-textarea
            color="black"
            v-model="dadosSolicitacaoAtendimento.mensagem"
            name="input-7-1"
            label="Mensagem"
            value=""
            :rules="validationMensagem"
            outlined
          ></v-textarea>
          
          <div :class="{'container-botoes':isModal, 'container-botoes-email':!isModal}">
            <v-btn 
              v-if="isModal"
              class="btn botao-cancelar font bg-meio appBG-blue appColor-white"
              @click='$emit("fecharModal", false)'
              >Cancelar</v-btn
            >

            <v-btn
              class="btn font bg-meio appBG-blue appColor-white "
              @click="enviarSolicitacao"
              >Enviar</v-btn
            >
          </div>
        </v-form>
      </div>
    </div>
  </v-card>
</template>

<script>
import ValidationService from "../../../admin/src/services/validation_service";
import CotacaoService from "../../services/cotacao_service"

export default {
  props: {
    isModal: Boolean
  },

  data: () => ({
    validationService: {},
    valid:{},
    dadosSolicitacaoAtendimento: {
      nome: '',
      email: '',
      assunto: '',
      telefone: '',
      mensagem: ''
    }
  }),

  beforeMount() {
    this.validationService = ValidationService
  },

  computed: {
    validationNome() {
      return [this.validationService.required("Informe o nome.")];
    },

    validationEmail() {
      return [
        this.validationService.required("Informe o e-mail."),
        this.validationService.isEmail(),
      ];
    },

    validationAssunto() {
      return [this.validationService.required("Informe o assunto.")];
    },

    validationTelefone() {
      return [
        this.validationService.required("Informe o telefone."),
        this.validationService.isTelefone(),
      ];
    },

    validationMensagem() {
      return [this.validationService.required("Informe a mensagem.")];
    },
  },

  methods: {
    enviarSolicitacao() {
      if (!this.$refs.form.validate() || !this.valid) {
        return;
      }

      const cotacaoService = new CotacaoService()
      
      cotacaoService.solicitarCotacao({
        onSucess: (status) => () => {
          if (status === 200) {
            this.$toast.open({
              position: 'top-right',
              message: 'Solicitação enviada com sucesso!',
              type: 'success',
              // all of other options may go here
            });
          }

          else { 
            console.log('status: ', status)
            this.$toast.open({
              position: 'top-right',
              message: 'Email enviado com sucesso!',
              type: 'error',
              // all of other options may go here
            });
          }
        },

        onError: (error) => {
          console.log(error)
        },

        onEnd: () => {
          setTimeout(() => {
            location.reload()
          }, 1400)
          // this.fecharModal(); 
        },
      }, this.dadosSolicitacaoAtendimento)
    },

    fecharModal() {
      this.$emit('fecharModal', false)
    }
  }
}
</script>

<style scoped>
.formtxt {
  font-size: 25px;
  font-weight: bold;
}
.algform {
  text-align: left;
  width: 100%;
}
.container {
  display: flex;
}
.algform .btncall {
  height: 59px;
}
.formimg img {
  width: 100%;
  margin-top: -5rem;
}

.container-botoes {
  display: flex;
  justify-content: space-between;
}

.container-botoes-email {
  display: flex;
  justify-content: center;
}

@media (max-width: 600px) {
  .container {
    display: block;
  }

  .input-modal {
    margin-right: 10rem;
  }

  .botao-cancelar {
    margin-bottom: 0.6rem;
  }
}
</style>