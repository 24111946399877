<template>
  <div class="home">
    <v-navigation-drawer v-model="abaFiltroMobile" fixed temporary width="60%">
      <botoes-filtro-mobile
        class="px-2 py-3"
        :filtro="filtro"
        @mudaFiltroProduto="mudaFiltroProdutos"
      />
    </v-navigation-drawer>
    <div>
      <header-geral />
    </div>

    <div class="container mtsc-4">
      <!-- filtro -->
      <!-- <div class="my-2">
      <v-row justify="center">
        <v-expansion-panels focusable>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h5>Filtrar</h5>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <filtroAgendamento
                @filtro="filtrarCalendario($event)"
                @limparFiltro="limparFiltro"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </div> -->
      <v-sheet height="64">
        <v-toolbar flat>
          <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday"
            >Hoje</v-btn
          >
          <v-select
            :items="unidades"
            label="Unidade"
            item-value="unidade_id"
            item-text="name"
            @change="atualizarHorarios"
            v-model="unidade_id"
          ></v-select>
          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon small> mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon small> mdi-chevron-right </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu bottom right>
            <!-- <template v-slot:activator="{ on, attrs }">
            <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
              <span>{{ calendario.labels[calendario.typeView] }}</span>
              <v-icon right>mdi-menu-down</v-icon>
            </v-btn>
          </template> -->
            <v-list>
              <v-list-item @click="calendario.typeView = 'day'">
                <v-list-item-title>{{
                  calendario.labels.day
                }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="calendario.typeView = 'week'">
                <v-list-item-title>{{
                  calendario.labels.week
                }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="calendario.typeView = 'month'">
                <v-list-item-title>{{
                  calendario.labels.month
                }}</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="600">
        <v-progress-linear
          v-if="loading"
          indeterminate
          color="primary"
        ></v-progress-linear>
        <v-calendar
          v-show="!loading"
          ref="calendar"
          v-model="calendario.focus"
          color="primary"
          :events="events"
          :event-more="false"
          :event-color="getEventColor"
          :type="calendario.typeView"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          v-on:moved="chengEvent"
        >
        </v-calendar>
      </v-sheet>

      <!-- MODAL - AGENDA -->
      <v-dialog  v-model="dialog" height="250px" max-width="500px">
        <modal-cadastro
          v-if="modalAgendar"
          @cancel="dialog=false"
          @response="closeDialog"
          :agenda="getEventModalInput(modalAgendarCtx.eventParsed.input.list_id)"
        />
        <!--
      <modal-detalhes-agenda
        v-if="modalVerDetalhesAgendamento"
        :detalhes="detalhesAgenda"
        @close="dialog = false"
        @desmarcar="tratarRespostaDesmarcar"
      />
      <modal-horario-desabilitado
        v-if="modalHabilitarHorario"
        @close="dialog = false"
        :HorarioVencido="vencido"
      /> -->
      </v-dialog>
    </div>

    <div class="mt-16">
      <footer-app />
    </div>
  </div>
</template>

<script>
import UnidadeService from "../services/unidades_service.js";
import AgendaService from "../services/agenda_service";
import AgendamentoService from "../services/agendamento_service";
import AplicacaoService from "../services/aplicacao_service";
import headerGeral from "@/components/template/header/header_geral.vue";
import footerApp from "@/components/template/footer_app.vue";
import modalCadastro from "@/components/agendamento/agendar_admin.vue";

export default {
  components: {
    headerGeral,
    footerApp,
    modalCadastro,
  },

  beforeMount() {
    if (!this.$store.state.token) {
      this.$router.push("/");
    }
  },

  mounted() {
    this.atualizaDados()
  },

  watch: {
    unidadesSelect() {
      return this.unidades;
    },
  },

  computed: {
    events() {
      const events = [];

      for (let i = 0; i < this.Horarios.length; i++) {
        // const first = new Date(horarioAtual.data)
        // const second = new Date(first.getTime() + secondTimestamp)
        const horarioAtual = this.Horarios[i];
        const agendado = this.agendamentosCliente.some(ag => ag.agenda_id == horarioAtual.id)
        const cor = agendado ? this.colors[3] : this.colors[4];

      events.push({
          id: horarioAtual.id,
          name: horarioAtual.turno,
          start: horarioAtual.data.split(" ")[0],
          // end: second,
          color: cor,
          list_id:
            horarioAtual.turno.charAt(0) + horarioAtual.data.split(" ")[0],
          // timed: !allDay,
        });
      }

      return events;
    },
  },

  methods: {

    closeDialog() {
      this.dialog = false
      this.atualizaDados()
    },

    atualizaDados() {
      this.atualizarHorarios();
      this.atualizaUnidades();
      this.atualizaAplicacoes();
      this.getAgendamentosCliente();

      this.$refs.calendar.checkChange();
    },

    getAgendamentosCliente() {
      const agendamentoService = new AgendamentoService();

      const onSucess = (status) => (body) => {
        if (status === 200) {
          this.agendamentosCliente = body.data.agendamentos;
        } else {
          return;
        }
      };

      const onError = (error) => {
        console.log(error);
      };

      const onEnd = () => {
        return; //
      };

      agendamentoService.getList({ onSucess, onError, onEnd });
    },

    atualizaAplicacoes() {
      const aplicacaoService = new AplicacaoService();

      const onSucess = (status) => (body) => {
        if (status === 200) {
          this.aplicacoes = body.data;
        } else {
          return;
        }
      };

      const onError = (error) => {
        console.log(error);
      };

      const onEnd = () => {
        return; //
      };

      aplicacaoService.getList({ onSucess, onError, onEnd });
    },

    atualizaUnidades() {
      const unidadeService = new UnidadeService();

      const onSucess = (status) => (body) => {
        if (status === 200) {
          this.unidades = body.data.unidades.map((unidade) => {
            return {
              unidade_id: unidade.id,
              name: unidade.nome_fantasia,
            };
          });

          this.unidade_id = this.unidades[0].unidade_id;
        } else {
          return;
        }
      };

      const onError = (error) => {
        console.log(error);
      };

      const onEnd = () => {
        this.atualizarHorarios();
        return; //
      };

      unidadeService.getList({ onSucess, onError, onEnd });
    },

    mudaFiltroProdutos(id) {
      this.filtro = id;
    },
    viewDay({ date }) {
      this.calendario.focus = date;
      // this.calendario.typeView = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.calendario.focus = "";
    },
    chengEvent() {
      this.atualizarHorarios();
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent(context) {
      this.modalAgendarCtx = context;
      this.modalAgendar = true;
      this.dialog = true;

      // if (marcado) {
      //   this.detalhesAgenda = {
      //     idAgenda: horarioObj.idAgenda,
      //     procedimento: horarioObj.procedimento,
      //     idPaciente: horarioObj.idPaciente,
      //     tipoProcedimento: horarioObj.name,
      //     idTipoProcedimento: horarioObj.idTipoProcedimento,
      //     idAgendamento: horarioObj.idAgendamento,
      //     horarioInicial: horarioObj.horarioInicial,
      //     horarioFinal: horarioObj.horarioFinal,
      //     data: horarioObj.data,
      //     vencido: horarioObj.vencido,
      //   };
      // } else if (!vencido) {
      //   this.detalhesAgenda =  horarioObj
      // }
    },
    getEventModalInput(list_id) {
      const agenda = this.events.filter(e => e.list_id == list_id)[0]
      const vacinas = this.aplicacoes

      return { agenda, vacinas }

    },
    atualizarHorarios() {
      this.loading = true;
      const horarios = new AgendaService();
      this.Horarios = [];

      const onSucess = (status) => (body) => {
        if (status === 200) {
          this.Horarios = body.data.horarios.map((horario) => {
            return {
              id: horario.agenda_id,
              turno: ["Manhã", "Tarde", "Noite"][parseInt(horario.turno) - 1],
              data: horario.data.split(" ")[0],
              produtos: this.aplicacoes
            };
          });
        } else {
          console.log(body);
        }
      };
      
      const onError = (error) => {
        console.log(error);
      };

      const onEnd = () => {
        this.loading = false;
      };

      horarios.getAllAgenda(
        { onSucess, onError, onEnd },
        { unidade: this.unidade_id }
      );
    },
  },
  data: () => ({
    abaFiltroMobile: null,
    unidades: [],
    unidade_id: -1,
    dialog: false,
    filtro: -1,
    agendamentosCliente: [],
    unidadesSelect: [],
    type: "month",
    types: ["month", "week", "day", "4day"],
    mode: "stack",
    modes: ["stack", "column"],
    weekday: [0, 1, 2, 3, 4, 5, 6],
    aplicacoes: [],
    weekdays: [
      { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
      { text: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
      { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },
      { text: "Mon, Wed, Fri", value: [1, 3, 5] },
    ],
    value: "",
    modalAgendarCtx: {},
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1",
    ],
    dialogCadastrar: false,
    dialogEdicao: false,
    dialogDelete: false,
    editedHorarios: {},
    search: "",
    totalList: 10,
    options: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      ordenacao: false,
      desc: false,
    },
    headers: [
      {
        text: "Nome",
        align: "start",
        sortable: true,
        value: "nome",
      },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    Horarios: [],
    loading: false,
    corHorarioCancelado: "grey",
    corHorarioOcupado: "primary",
    corHorarioLivre: "#3e682a",
    typeViewCalendario: "month",
    labelsCalendario: {
      month: "Mês",
    },
    calendario: {
      labels: {
        month: "Mês",
        week: "Semana",
        day: "Dia",
      },
      focus: "",
      typeView: "month",
    },
    modalAgendar: false,
    horarios: [],
    detalhesAgenda: {},
    vencido: true,
    params: {},
  }),
};
</script>

<style scoped>
.banner-principal {
  margin-top: 8rem;
}

.mtsc-4 {
  margin-top: 10rem;
}

@media (max-width: 600px) {
  .mtsc-4 {
    margin-top: 2rem;
  }
}
</style>
