<template>
  <div>
    <div>
      <header-geral />
    </div>
    <div class="container margintop-menu">
      <div class="historico text-left">
        <router-link class="font-weight-bold mr-3 text-dark" to="/"
          >Home</router-link
        >
        <router-link
          class="font-weight-bold appColor-primary"
          to="/quem-somos"
        >
          <span class="mr-3">></span>
          <span>Quem somos</span>
        </router-link>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <h1 class="text-left mt-16">Quem somos</h1>
          <div class="pilares">
            <div class="missao mb-8">
              <div class="pl-3" style="border-left: 3px solid #a92429">
                <h3 class="text-left mt-8">Missão</h3>
              </div>
              <p class="text-left">
                Ofertar vacinas para crianças, adolescentes, adultos e idosos
                com vistas a promover saúde e qualidade de vida.
              </p>
            </div>
            <div class="visao mb-8">
              <div class="pl-3" style="border-left: 3px solid #a92429">
                <h3 class="text-left mt-8">Visão</h3>
              </div>
              <p class="text-left">
                Ser referência para vacinação de crianças, adolescentes, adultos
                e idosos em Fortaleza, oferecendo serviço de qualidade, buscando
                a consolidação de boas práticas, credibilidade e satisfação da
                clientela assistida.
              </p>
            </div>
            <div class="valores mb-8">
              <div class="pl-3" style="border-left: 3px solid #a92429">
                <h3 class="text-left mt-8">Valores</h3>
              </div>
              <p class="text-left">
                Ética, competência, dedicação, segurança e qualidade nas suas
                atividades, com o entendimento de que a vacinação é um
                procedimento que envolve respeito à vida e às pessoas.
              </p>
            </div>
            <div class="lema mb-8">
              <div class="pl-3" style="border-left: 3px solid #a92429">
                <h3 class="text-left mt-8">Lema</h3>
              </div>
              <p class="text-left">
                Vacinar é promover saúde e qualidade de vida.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="d-flex align-items-center h-100">
            <v-img src="@/assets/images/doctors.jpg"></v-img>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <h1 class="text-left mt-16">Corpo clínico</h1>
        </div>
        <div class="mt-8"> 
          <carrossel-index :media="calcularMedia()"/>
        </div>
      </div>
    </div>
    <div class="mt-16">
      <painel-redes-sociais />
    </div>
    <div class="mt-16">
      <footer-app />
    </div>
  </div>
</template>

<script>
import headerGeral from "@/components/template/header/header_geral.vue"
import carrosselIndex from "@/components/quem_somos/carrossel/index.vue"
import painelRedesSociais from "@/components/home/painel_redes_sociais.vue";
import footerApp from "@/components/template/footer_app.vue";

export default {
  components: {
    headerGeral,
    carrosselIndex,
    painelRedesSociais,
    footerApp,
  },
  methods: {
    calcularMedia() {
      let media = "";
      if (window.matchMedia("(min-width: 1264px)").matches) {
        media = "lg";
      }
      if (
        window.matchMedia("(min-width: 960px) and (max-width: 1263px)").matches
      ) {
        media = "md";
      }
      if (
        window.matchMedia("(min-width: 600px) and (max-width: 959px)").matches
      ) {
        media = "sm";
      }
      if (window.matchMedia("(max-width: 599px)").matches) {
        media = "xs";
      }
      return media;
    },
  },
};
</script>