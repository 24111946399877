<template>
  <div>
    <div v-if="media == 'xs' || media == 'sm'">
        <carrossel-mobile />
    </div>
    <div v-else> 
        <carrossel-desktop />
    </div>
  </div>
</template>

<script>
import carrosselDesktop from '@/components/quem_somos/carrossel/carrossel_desktop.vue'
import carrosselMobile from '@/components/quem_somos/carrossel/carrossel_mobile.vue'

export default {
    props: {
        media: String
    },
    components: {
        carrosselDesktop,
        carrosselMobile
    }
}
</script>