<template>
  <div>
    <div class="container">
      <Menu_loja />
    </div>
    <div class="bg-servicos">
      <div class="col-6">
        <div class="appBG-primary blockhead">
          <p class="tit appColor-white">Seguros</p>
        </div>
        <p class="appColor-white linkhead font">
          <a href="/">Home</a> >> <a href="../servicos">Seguros</a>
        </p>
      </div>
    </div>
    <div class="mb-5">
      <sobre-enseada />
    </div>
    <div class="mt-16">
      <footer-app />
    </div>
  </div>
</template>

<script>
import VuexUsuarioMixin from "@/mixins/vuex_usuario_mixin.js";
import VuexTokenMixin from "@/mixins/vuex_token_mixin.js";
import footerApp from "@/components/template/footer_app.vue";
import CarrinhoMixin from "@/mixins/carrinho_mixin.js";
import CompraService from "@/services/compras_service";
import Menu_loja from "../components/template/header/navbar/menu_loja.vue";
import sobreEnseada from "@/components/template/sobre_enseada.vue";

export default {
  data: () => ({
    pedidos: [],
    carrinhoProdutos: [],
    totalCompra: 0,
    quantidadeCompra: 0,
  }),
  components: {
    footerApp,
    Menu_loja,
    sobreEnseada,
  },

  mixins: [VuexUsuarioMixin, VuexTokenMixin, CarrinhoMixin],

  beforeMount() {
    this.atualizarCarrinhoCompra();
  },

  /*watch: {
    carrinhoProdutos: {
      handler: function (newcarrinho) {
        console.log(newcarrinho);
        this.atualizarTotalCompra(newcarrinho);
        this.atualizarquantidadeCompra(newcarrinho);
      },
      deep: true,
    },
  },*/

  methods: {
    // logout() {
    //   this.$_ACTIONS_setUsuario({});
    //   this.$_ACTIONS_setToken("");
    //   this.$router.replace("login");
    // },
    atualizarCarrinhoCompra() {
      this.carrinhoProdutos = this.$_GETTERS_carrinho;
    },
    addToCartBatch(pedido) {
      pedido.pacotes.map((p) => {
        let carrinho = {
          titulo: p.pacote_nome,
          // valorTotal: parseFloat(p.preco) * p.quantidade,
          valorUnitario: parseFloat(p.preco),
          quantidade: parseInt(p.quantidade),
          id: p.pacote_id,
        };

        this.$_ACTIONS_carrinho(carrinho);
      });
    },
  },

  filters: {
    statusPagamento(status) {
      const stts = {
        waiting: "aguardando",
        paid: "pago",
        declined: "recusado",
      };

      return stts[status.toLowerCase()];
    },

    fillLeft: function (value = "", qtd = 0) {
      let v = value.toString();
      if (v.length < qtd) {
        return "0".repeat(qtd - v.length) + v;
      }

      return v;
    },

    formatData: function (data) {
      return data.substr(0, 10).split("-").reverse().join("/");
    },
  },

  mounted() {
    const compraService = new CompraService();
    compraService.getAll({
      onSucess: (status) => (body) => {
        if (status === 200) {
          this.pedidos = body.data.compras;
        }

        if (status === 400 || status === 500) {
          this.$router.push({ name: "login" });
        }
      },
      onError: () => {},
      onEnd: () => {
        this.loading = false;
      },
    });
  },

  // attachToCard(compra) {
  //   compra.pacotes.forEach(pacote => {
  //     //
  //   })
  // },
};
</script>


<style scoped>
.nav-tabs .nav-link.active,
.nav-tabs:focus {
  border-color: transparent transparent #3178ac;
  border-bottom: 3px solid #3178ac;
  color: #3178ac;
  font-weight: 600;
}
.nav-link:hover,
.nav-tabs {
  border-color: transparent;
  color: #3178ac;
  font-weight: 600;
}
.titles {
  margin-left: 29px;
  font-size: 29px;
}
small {
  text-align: left;
  font-weight: 600;
  color: #757575;
}
.card-img-wrapper {
  max-width: 128px;
  border-radius: 5px;
}
.card-rel {
  display: flex;
}
h4,
p {
  text-align: left;
}

.detalhes-pedido {
  font-weight: bold;
  cursor: pointer;
  font-size: 14px;
}
.comprar-button {
  text-transform: unset;
  font-weight: bold;
}
</style>