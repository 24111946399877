<template>
  <div>
    <div class="container text-left p-5">
      <h2 class="titles appColor-blue mt-5">COMODIDADES DO HOTEL</h2>
      <div
        class="como row mt-5 font font-info"
        v-for="(produto, i) in produtos"
        :key="i"
      >
        <div class="d-flex">
          <v-icon class="icon">mdi-wifi</v-icon>
          <p>{{ produto.descricao }}</p>
        </div>
      </div>
      <div class="mt-10 d-flex filtro">
        <div>
          <b-button-group>
            <b-button class="btn btn-primary font btnfiltro"
              >Quartos Disponíveis</b-button
            >
          </b-button-group>
        </div>
        <!--AQUIII-->
        <div class="font">
          <v-select
            item-text="name"
            item-value="id"
            label="Tipo de tarifa"
            dense
            outlined
          >
          </v-select>
        </div>
        <div class="font">
          <v-select
            item-text="name"
            item-value="id"
            label="Tipo de refeição"
            dense
            outlined
          >
          </v-select>
        </div>
        <div class="font">
          <v-select
            label="Tipo de cama"
            item-text="name"
            item-value="id"
            dense
            outlined
          ></v-select>
        </div>
      </div>
      <div>
        <div class="col-12">
          <v-card
            class="col-12 d-flex flex-column mb-4 rounded-t-lg somb"
            elevation="0"
            outlined
          >
            <!--
                    v-for="pedido in pedidos"
                  :key="pedido.pedido_id"
                    
                    
                    <div

                    class="grey lighten-3 row rounded-t-lg grey--text"
                    style="padding: 0"
                  >
                    <small class="col"
                      >Pedido <br />n° {{ pedido.carrinho_id | fillLeft(8) }}</small
                    >
                    <small class="col"
                      >Realizado no dia <br />{{
                        pedido.compra_data | formatData
                      }}</small
                    >
                    <small class="col"
                      >Valor total <br />R${{
                        parseFloat(pedido.preco)
                          | money({
                            prefix: "",
                            suffix: "",
                            thousands: ".",
                            decimal: ",",
                            precision: 2,
                          })
                      }}</small>

                      <small class="col"
                        >Status <br />{{
                          pedido.compra_status | statusPagamento
                        }}</small
                      >
                    <small
                      @click="$router.push(`pedido/${pedido.carrinho_id}`)"
                      class="col-3 d-flex mt-2 appColor-primary detalhes-pedido"
                      >Exibir detalhes do pedido</small
                    >
                  </div>-->
            <v-card-text class="row teste">
              <!--<div class="col border card-img-wrapper">
                      <v-img
                        width="40"
                        class="mx-auto"
                        src="@/assets/images/minha-vacina.png"
                      ></v-img>
                    </div>-->

              <div class="col-2">
                <h4 class="appColor-blue font">{{ produto.titulo }}</h4>
                <p>
                  {{ produto.descricao }}
                </p>
              </div>
              <div class="col-3 cardb text-left benefic">
                <div>
                  <v-icon>mdi-coffee-outline</v-icon>
                  <span>Café da manhã</span>
                </div>
                <div>
                  <v-icon>mdi-bed-king-outline</v-icon>
                  <span>Standard duplo casal</span>
                </div>
                <div>
                  <v-icon>mdi-currency-usd</v-icon>
                  <span>Tarifa Reembolsável</span>
                </div>
              </div>
              <div class="col-6 cardb">
                <div class="d-flex">
                  <div class="col d-flex">
                    <h4 class="appColor-blue titles">
                      R$
                      {{
                        parseFloat(produto.valorTotal)
                          | money({
                            prefix: "",
                            suffix: "",
                            thousands: ".",
                            decimal: ",",
                            precision: 0,
                          })
                      }}
                    </h4>
                    <span class="diaria taxa"> /diária</span>
                  </div>
                  <div class="col">
                    <span class="list-group-item"
                      >Dia

                      <!-- {{
                            pedido.compra_data | formatData
                          }} -->
                    </span>
                  </div>
                </div>
                <span class="taxa">
                  Taxas e impostos inclusos | <b>até 12x</b>
                </span>
              </div>
            </v-card-text>
          </v-card>
          <v-btn
            class="appColor-blue text-left font"
            block
            href="detalhes-categoria"
            >Ver mais opções de quarto</v-btn
          >
        </div>
      </div>
    </div>
    <div>
      <footerApp />
    </div>
  </div>
</template>

<script>
import LojaService from "@/services/loja_service";

export default {
  mounted() {
    this.getList();
  },
  data: () => {
    return {
      produtos: [],
    };
  },
  methods: {
    getList() {
      this.loading = true;
      const lojaService = new LojaService();
      this.produtos = [];
      const onSucess = (status) => (body) => {
        if (status === 200) {
          this.produtos = body.data.loja.map((pack) => ({
            src: require("@/assets/images/imgcar.png"),
            titulo: pack.nome,
            // junta o titulo dos produtos
            descricao: pack.produtos.reduce(
              (acc, p) => acc + ` - ${p.produto}`,
              ""
            ),
            // valorAnterior: "R$ 350",
            valorAtual: pack.preco,

            // preco_parcelado: "",
            href: `/detalhes-produto/${pack.pacote_id}`,
          }));
        } else {
          // console.log(body);
        }
      };
      const onError = (error) => {
        console.log(error);
      };
      const onEnd = () => {
        this.loading = false;
      };
      lojaService.getList({ onSucess, onError, onEnd });
    },
    detalhesProduto(produtoId) {
      this.$router.replace({
        name: "detalhes-produto",
        params: { produtoId: produtoId },
      });
    },
  },
};
</script>

<style scoped>
.filtro {
  justify-content: space-around;
}
.como p {
  margin: 6px 1px 0px 8px;
}
.como {
  justify-content: space-between;
}
.font-info {
  font-size: 20px;
}
.benefic {
  margin-right: 1rem;
  padding: 3% !important;
}
.benef {
  border: 3px solid #aaaaaa;
  border-radius: 8px;
  width: 100%;
  color: #aaaaaa;
  margin-top: 1rem;
  font-size: 22px;
}
.benef v-icon {
  color: #aaaaaa !important;
}
.barra {
  border: 1px solid #707070;
}
.bloc-info {
  box-shadow: 0px 6px 16px #00000029;
  padding: 2%;
  margin-bottom: 2rem;
  border-radius: 13px;
}
.icon {
  color: #000000 !important;
  background-color: #e8e8e8;
  padding: 12px;
  border-radius: 31px;
}
.btnfiltro {
  background-color: #2b3a8f;
  border: 1px solid #2b3a8f;
}
</style>
