<template>
  <div>
    <div class="row">
      <div class="col-sm-9">
        <h4 class="text-left font-weight-bold">
          {{ item.titulo }}
        </h4>
        <h3 class="appColor-primary font-weight-bold text-left">
          R$ {{ (item.valorTotal = item.valorUnitario * item.quantidade).toFixed(2) }}
        </h3>
      </div>
      <div class="col-sm-3">
        <v-btn class="appColor-secondary" @click="$emit('removerItem')" plain>
          Remover
        </v-btn>
        <div class="contador d-flex justify-content-between align-items-center">
          <v-icon
            class="app-Border-primary appColor-primary"
            @click="$emit('diminuirQuantidade')"
            :disabled="item.quantidade <= 1 ? true : false"
          >
            mdi-minus
          </v-icon>
          <h3 class="font-weight-light">
            {{ item.quantidade }}
          </h3>
          <v-icon
            class="app-Border-primary appColor-primary"
            @click="$emit('aumentarQuantidade')"
          >
            mdi-plus
          </v-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
  },
  mounted() {
    this.item.valorTotal = this.item.valorUnitario * this.item.quantidade;
  },
};
</script>

<style scoped>
.v-icon--disabled {
  border: 1px solid #cdcdcd;
}
</style>