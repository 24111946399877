
export default class ValidationService {
    static regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    static regexHorario24Horas = /^([01][0-9]|2[0-3]):[0-5][0-9]$/
    static regexGetHorarioPuro = /(?!:)(\s|\D)/g
    static regexTelefone = /^(\(\d{2,4}\))?\s?\d{3,5}-\d{4}$/
    static regexCPF = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/
    static regexCNPJ = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/
    static regexCEP = /^\d{5}-\d{3}$/
    
    static required(errorMessage = 'Campo obrigatório.') {
        return value => {
            return !!value || errorMessage;
        }
    }

    static isNotEmpty (errorMessage = 'O campo deve ser preenchido') {
        return value => {
            if (value instanceof Object) {
                if (value instanceof Array) {
                    return value.length > 0 || errorMessage
                } else {
                    return JSON.stringify(value) !== '{}' || errorMessage
                }
            } else {
                return ValidationService.required(errorMessage)(value)
            }
        }
    }

    static minLength (minimumLength, errorMessage = 'O campo não possui a quantidade mínima de caracteres.') {
        return value => {
            return value.length >= minimumLength || errorMessage
        }
    }

    static isEmail (errorMessage = 'Informe um email válido.') {
        return value => {
            return ValidationService.regexEmail.test(value) || errorMessage
        }
    }

    static isHorario24Horas (errorMessage = 'Horário inválido.') {
        return value => {
            if (!value) {
                return true
            }
            const horarioTratado = value.replace(ValidationService.regexGetHorarioPuro, '')
            return ValidationService.regexHorario24Horas.test(horarioTratado) || errorMessage
        }
    }   
    static horarioMaiorQue (horarioMenor, horarioMaior, segundosMinimoDiferenca = 1, segundosMinimoDiferencaError ,errorMessage = 'A hora final deve ser maior que a inicial.') {
        return () => {
            const horariosSaoInvalidos = typeof ValidationService.isHorario24Horas(horarioMenor) === 'string' || 
            typeof ValidationService.isHorario24Horas(horarioMaior) === 'string'
            if (horariosSaoInvalidos || !horarioMenor || !horarioMaior ) {
                /*
                 * Se pelo menos um horário for inválido, pular esta validação.
                 */
                return true
            } else {
                const getData = horario => {
                    const date = new Date()
                    const hours = horario.substr(0,2)
                    const minutes = horario.substr(3,2)
                    date.setHours(hours)
                    date.setMinutes(minutes)
                    return date
                }
                const dateMenor = getData(horarioMenor)
                const dateMaior = getData(horarioMaior)    
                const milisecDateMenor = dateMenor.getTime()
                const milisecDateMaior = dateMaior.getTime()
                const diferencaEmMilisec = milisecDateMaior - milisecDateMenor;
                const diferencaEmSegundos = Math.round(diferencaEmMilisec / 1000);
                return milisecDateMenor < milisecDateMaior
                    ? diferencaEmSegundos >= segundosMinimoDiferenca || segundosMinimoDiferencaError
                    : errorMessage
            }
        }
    }

    static dataMaiorQue (dataMenor, dataMaior, errorMessage = 'A data final deve ser maior que a inicial.') {
        return () => {
            const dateMenor = new Date(dataMenor)
            const dateMaior = new Date(dataMaior)
            const timeMenor = dateMenor.getTime()
            const timeMaior = dateMaior.getTime()
            return isNaN(timeMenor) || isNaN(timeMaior)
                ? true
                : dateMenor.getTime() < dateMaior.getTime() || errorMessage
        }
    }

    static diferencaEntreDatas (dataMenor, dataMaior, diasMinimos, errorMessage = 'A data final deve ser maior que a inicial.') {
        return () => {
            const dateMenor = new Date(dataMenor)
            const dateMaior = new Date(dataMaior)
            const timeMenor = dateMenor.getTime()
            const timeMaior = dateMaior.getTime()
            const diasDiferenca = (timeMaior - timeMenor) / (1000 * 60 * 60 * 24)
            return isNaN(timeMenor) || isNaN(timeMaior)
                ? true
                : diasDiferenca <= diasMinimos || errorMessage
        }
    }

    static match (valueToMatch, errorMessage = 'Os campos devem ter o mesmo valor', caseSensitive = true) {
        return value => {
            const isValid = caseSensitive 
                ? value === valueToMatch 
                : value.toLowerCase() === valueToMatch.toLowerCase()
            return isValid || errorMessage
        }
    }

    static doesNotMatch (valueThatCannotMatch, errorMessage = 'Os campos não podem ter o mesmo valor', caseSensitive = true) {
        return value => {
            return ValidationService.match(valueThatCannotMatch, false, caseSensitive)(value)
                ? errorMessage
                : true   
        }
    }

    static isTelefone (errorMessage = 'O campo deve ser um telefone') {
        return value => {
            if (!value) {
                return true
            }
            return ValidationService.regexTelefone.test(value) || errorMessage
        }
    }

    static validarCPF(cpf) {	
        cpf = cpf.replace(/[^\d]+/g,'');	
        if(cpf == '') return false;	
        // Elimina CPFs invalidos conhecidos	
        if (cpf.length != 11 || 
            cpf == "00000000000" || 
            cpf == "11111111111" || 
            cpf == "22222222222" || 
            cpf == "33333333333" || 
            cpf == "44444444444" || 
            cpf == "55555555555" || 
            cpf == "66666666666" || 
            cpf == "77777777777" || 
            cpf == "88888888888" || 
            cpf == "99999999999")
                return false;		
        // Valida 1o digito	
        add = 0;	
        for (var i=0; i < 9; i ++)		
            add += parseInt(cpf.charAt(i)) * (10 - i);	
            rev = 11 - (add % 11);	
            if (rev == 10 || rev == 11)		
                rev = 0;	
            if (rev != parseInt(cpf.charAt(9)))		
                return false;		
        // Valida 2o digito	
        var add = 0;	
        for (i = 0; i < 10; i ++)		
            add += parseInt(cpf.charAt(i)) * (11 - i);	
        var rev = 11 - (add % 11);	
        if (rev == 10 || rev == 11)	
            rev = 0;	
        if (rev != parseInt(cpf.charAt(10)))
            return false;		
        return true;   
    }

    static isCPF (errorMessage = 'Informe um CPF válido') {
        return value => {
            if (!value) {
                return true
            }
            return (ValidationService.regexCPF.test(value) && ValidationService.validarCPF(value)) 
                || errorMessage
        }
    }

    static validarCNPJ(cnpj) {
 
        cnpj = cnpj.replace(/[^\d]+/g,'');
     
        if(cnpj == '') return false;
         
        if (cnpj.length != 14)
            return false;
     
        // Elimina CNPJs invalidos conhecidos
        if (cnpj == "00000000000000" || 
            cnpj == "11111111111111" || 
            cnpj == "22222222222222" || 
            cnpj == "33333333333333" || 
            cnpj == "44444444444444" || 
            cnpj == "55555555555555" || 
            cnpj == "66666666666666" || 
            cnpj == "77777777777777" || 
            cnpj == "88888888888888" || 
            cnpj == "99999999999999")
            return false;
             
        // Valida DVs
        var tamanho = cnpj.length - 2
        var numeros = cnpj.substring(0,tamanho);
        var digitos = cnpj.substring(tamanho);
        var soma = 0;
        var pos = tamanho - 7;
        for (var i = tamanho; i >= 1; i--) {
          soma += numeros.charAt(tamanho - i) * pos--;
          if (pos < 2)
                pos = 9;
        }
        var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(0))
            return false;
             
        tamanho = tamanho + 1;
        numeros = cnpj.substring(0,tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (i = tamanho; i >= 1; i--) {
          soma += numeros.charAt(tamanho - i) * pos--;
          if (pos < 2)
                pos = 9;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(1))
              return false;
               
        return true;
        
    }

    static isCNPJ (errorMessage = 'Informe um CNPJ válido') {
        return value => {
            if (!value) {
                return true
            }
            return (ValidationService.regexCNPJ.test(value) && ValidationService.validarCNPJ(value)) 
                || errorMessage
        }
    }

    // static isCPF (errorMessage = 'Informe um CPF válido') {
    //     return value => {
    //         if (!value) {
    //             return true
    //         }
    //         return ValidationService.regexCPF.test(value) || errorMessage
    //     }
    // }

    

    static isCEP (errorMessage = 'Informe um CEP válido') {
        return value => {
            if (!value) {
                return true
            }
            return ValidationService.regexCEP.test(value) || errorMessage
        }
    }
}
