<template>
    <div class="container-modal-beneficiario p-3">
        <modal 
            name="modal-aviso-beneficiario"
            :adaptive="true"
        >
            <div class="container-modal p-3">
                <h1>Atenção</h1>
                <p style="text-align: justify;">Você precisa preencher os dados de mais {{this.quantidadeBeneficiarios - this.quantidadeBeneficiariosCadastrados}} beneficiários. Clique para prosseguir com o cadastro</p>
                <div>
                    <v-btn 
                        class="botao-modal-aviso"
                        @click='continuarCadastro'
                    >
                        <span style="text-transform: capitalize; font-weight: bold; color: #fff">Continuar</span>
                    </v-btn>
                </div>
            </div>
        </modal>
        <v-card 
            class="container-card"
            elevation="5"
        >
            <h1 style="color: #3178ac;">Cadastrar beneficiário</h1>
            <div class="informativo">
                <strong style="color: rgba(27,40,24,0.49)">OBS: Você tem {{this.quantidadeBeneficiariosCadastrados}} de {{this.quantidadeBeneficiarios}} beneficiários cadastrados</strong>
            </div>
            <v-form ref="form" v-model="valid" lazy-validation>
                <div >
                    <div class="p-2 row">
                        <div class="col-12">
                            <v-text-field
                                color="black"
                                label="Nome completo"
                                hide-details="auto"
                                v-model="segurado.nome"
                                :rules="validationNome"
                                required
                            ></v-text-field>
                        </div>

                        <div class="col-12">
                            <v-text-field
                                color="black"
                                label="E-mail"
                                v-model="segurado.email"
                                :rules="validationEmail"
                                clear-icon="clear"
                                :type="'email'"
                            ></v-text-field>
                        </div>

                        <div class="col-lg-6">
                            <v-text-field
                                color="black"
                                v-model="segurado.telefone"
                                label="Telefone"
                                required
                                :rules="validationTelefone"
                                v-mask="'(##) #####-####'"
                            ></v-text-field>
                        </div>

                        <div class="col-lg-6">
                            <v-text-field
                                color="black"
                                v-model="segurado.dataDeNascimento"
                                :type="'date'"
                                :label="dataLabel"
                                id="nascimentoUsuario"
                                @focus="onFocus()"
                                @focusout="onBlur()"
                            ></v-text-field>
                        </div>

                        <div class="col-lg-6">
                            <v-text-field
                                color="black"
                                v-model="segurado.cpf"
                                :rules="validationCpf"
                                label="CPF"
                                v-mask="'###.###.###-##'"
                            ></v-text-field>
                        </div>

                        <div v-if="tipoDeFormulario == 2" class="col-lg-6">
                            <v-text-field
                                color="black"
                                v-model="segurado.placa"
                                :rules="validationPlaca"
                                label="Placa"
                            ></v-text-field>
                        </div>

                        <div v-if="tipoDeFormulario == 2" class="col-lg-6">
                            <v-text-field
                                color="black"
                                v-model="segurado.modelo"
                                :rules="validationModelo"
                                label="Modelo"
                            ></v-text-field>
                        </div>

                        <div class="col-lg-6">
                            <div class="item-form">
                                <v-text-field
                                    label="Cep"
                                    v-model="segurado.cep"
                                    :rules="validationCep"
                                    clear-icon="clear"
                                    required
                                    v-mask="'#####-###'"
                                    :type="'text'"
                                    :outlined="false"
                                    @blur="buscaCepSegurado"
                                ></v-text-field>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="item-form">
                                <v-text-field
                                color="black"
                                v-model="segurado.rua"
                                :type="'text'"
                                label="Rua"
                                :rules="validationRua"
                                id="nascimentoUsuario"
                                @focus="onFocus()"
                                @focusout="onBlur()"
                                ></v-text-field>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="item-form">
                                <v-text-field
                                color="black"
                                v-model="segurado.numero"
                                :type="'text'"
                                label="Número"
                                :rules="validationNumero"
                                id="nascimentoUsuario"
                                @focus="onFocus()"
                                @focusout="onBlur()"
                                ></v-text-field>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="item-form">
                                <v-text-field
                                color="black"
                                v-model="segurado.complemento"
                                :type="'text'"
                                label="Complemento"
                                id="nascimentoUsuario"
                                @focus="onFocus()"
                                @focusout="onBlur()"
                                ></v-text-field>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="item-form">
                                <v-text-field
                                color="black"
                                v-model="segurado.bairro"
                                :type="'text'"
                                label="Bairro"
                                :rules="validationBairro"
                                id="nascimentoUsuario"
                                @focus="onFocus()"
                                @focusout="onBlur()"
                                ></v-text-field>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="item-form">
                                <v-text-field
                                color="black"
                                v-model="segurado.cidade"
                                :type="'text'"
                                label="Cidade"
                                :rules="validationCidade"
                                id="nascimentoUsuario"
                                @focus="onFocus()"
                                @focusout="onBlur()"
                                ></v-text-field>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <!-- <div class="item-form">
                                <v-text-field
                                color="black"
                                v-model="segurado.estado"
                                :type="'text'"
                                label="Estado"
                                :rules="validationEstado"
                                id="nascimentoUsuario"
                                @focus="onFocus()"
                                @focusout="onBlur()"
                                ></v-text-field>
                            </div> -->
                            <v-select
                                color="black"
                                v-model="segurado.estado"
                                :items="uf"
                                label="Estado"
                                :rules="validationEstado"
                            ></v-select>
                        </div>

                        <div class="container-botoes mt-2">
                            <v-btn 
                                class="botao-voltar"
                                @click="voltar"
                            >
                                <strong style="color: #fff">Voltar</strong>
                            </v-btn>
                            <v-btn 
                                class="botao-finalizar"
                                @click="finalizar"
                                :disabled="botaoHabilitado"
                            >
                                <strong style="color: #fff">Finalizar</strong>
                            </v-btn>
                        </div>
                    </div>
                </div>
            </v-form>
        </v-card>
    </div>
</template>

<script>
import ValidationService from "../../../admin/src/services/validation_service";
import CEPService from "../../services/cep_service";
import LojaService from "@/services/loja_service";
import UsuarioService from "@/services/usuario_service";

export default {
    props: {
        carrinhoId: Number,
        pacoteId: Number,
        pacotes: Array, 
    },

    data: () => ({
        dadosPessoais: true,
        tituloDoBotao: 'Próximo',
        validationService: {},
        quantidadeBeneficiarios: '',
        quantidadeBeneficiariosCadastrados: '',
        tituloSeguro: '',
        uf: [
            'AC',
            'AL',
            'AP',
            'AM',
            'BA',
            'CE',
            'DF',
            'ES',  
            'GO',
            'MA',
            'MT',
            'MS',
            'MG',
            'PA',
            'PB',
            'PR',
            'PE',
            'PI',
            'RJ',
            'RN',
            'RS',
            'RO',
            'RR',
            'SC',
            'SP',
            'SE',
            'TO',
        ],
        segurado: {
            nome: '',
            email: '',
            telefone: '',
            cpf: '',
            placa: '',
            modelo: '',
            dataDeNascimento: '',
            cep: '',
            rua: '',
            numero: '',
            complemento: '',
            bairro: '',
            cidade: '',
            estado: ''
        },
        tipoDeFormulario: '',
        botaoHabilitado: false
    }),

    beforeMount() {
        this.validationService = ValidationService;
        this.getSeguroInfo()
        this.getProdutoInfo(this.pacoteId)
        this.getQuantidadeSeguradosCadastrados(this.carrinhoId)
        this.desabilitarBotao()
    },

    mounted() {
        console.log('carrinho: ', this.carrinhoId)
        console.log('pacoteId: ', this.pacoteId)
        console.log('quantidadeBeneficiarios: ', this.quantidadeBeneficiarios)
    },

    computed: {
    validationNome() {
      return [this.validationService.required("Informe o nome.")];
    },

    validationEmail() {
      return [
        this.validationService.required("Informe o e-mail."),
        this.validationService.isEmail(),
      ];
    },

    validationTelefone() {
      return [
        this.validationService.required("Informe o telefone."),
        this.validationService.isTelefone(),
      ];
    },

    validationCpf() {
      return [
        this.validationService.required("Informe o cpf."),
        this.validationService.isCPF(),
        this.validationService.validarCPF(this.segurado.cpf)
      ];
    },

    validationPlaca() {
        return [
            this.validationService.required("Informe a placa."),
        ];
    },

    validationModelo() {
        return [
            this.validationService.required("Informe o modelo."),
        ];
    },

    validationCnpj() {
      return [
        this.validationService.required("Informe o cnpj."),
        this.validationService.isCNPJ(),
      ];
    },

    validationCep() {
      return [
        this.validationService.required("Informe o cep."),
        this.validationService.isCEP(),
      ];
    },

    validationRua() {
      return [this.validationService.required("Informe a rua.")];
    },

    validationNumero() {
      return [this.validationService.required("Informe o numero.")];
    },

    validationBairro() {
      return [this.validationService.required("Informe o bairro.")];
    },

    validationCidade() {
      return [this.validationService.required("Informe a cidade.")];
    },

    validationEstado() {
      return [this.validationService.required("Informe o estado.")];
    },
  },

    methods: {
        avancarEtapa() {
            this.dadosPessoais = false
            this.tituloDoBotao = 'Finalizar'
        },

        voltarEtapa() {
            
        },

        desabilitarBotao() {
            if (this.quantidadeBeneficiarios - this.quantidadeBeneficiariosCadastrados <= 0) {
                this.botaoHabilitado = true
            }
        },

        getQuantidadeSeguradosCadastrados(idDoCarrinho) {
            const usuarioService = new UsuarioService();
            usuarioService.retornarTotalSeguradosCadastrados({
                onSucess: (status) => (body) => {
                    if (status === 200) {
                        let segurados = body.data.segurados

                        for (let segurado of segurados) {
                            console.log('cadastrados\npacote_id (segurado): ', segurado.pacote_id)
                            console.log('cadastrados\npacoteId (componente): ', this.pacoteId)
                            if (this.pacoteId === segurado.pacote_id) {
                                // this.quantidadeBeneficiariosCadastrados = typeof segurados === 'undefined' ? 0 : segurados.total
                                this.quantidadeBeneficiariosCadastrados = segurado.total
                                break
                            } else {
                                this.quantidadeBeneficiariosCadastrados = 0
                            }
                        }
                        this.desabilitarBotao()
                    }
                }, 

                onError: () => {},
                
                onEnd: () => {}
            }, idDoCarrinho)
        },

        buscaCepSegurado() {
            this.cep = this.segurado.cep;
            const cepService = new CEPService();
            this.loading = true;
            cepService.buscaCep(
                {
                onSucess: (status) => (body) => {
                    if (status === 200) {
                        this.segurado.rua = body.logradouro;
                        // this.segurado.estado = body.uf;
                        this.segurado.cidade = body.localidade;
                        this.segurado.bairro = body.bairro;
                        // this.segurado.complemento = body.complemento;
                        // this.boleto.uf = body.uf;
                        // this.boleto.uf_1 = this.converterEstados(body.uf);
                    }
                },
                onError: (error) => {
                    return error;
                },
                onEnd: () => {
                    this.loading = false;
                },
                },
                this.cep.replace("-", "").replace(".", "")
            );
        },

        getProdutoInfo(pacoteId) {
            this.mostrarDetalhes = true;
            this.pacoteId = pacoteId
            const lojaService = new LojaService()

            lojaService.getOne({
                onSucess: (status) => (body) => {
                    if (status === 200) {
                        console.log('entrou pedidos')
                        let seguros = body.data.loja;

                        console.log()

                        this.tipoDeFormulario = seguros[0].formulario
                        
                        let seguro = seguros[0].produtos

                        // this.pacotes = this.segurosComprados[0].pacotes;
                        console.log('seguro: ', seguro)
                        this.tituloSeguro = seguro[0].produto;
                        this.descricaoSeguro = seguro[0].prevencao
                    }

                    else if (status === 400 || status === 500) {
                        this.$router.push({ name: "login" });
                    }
                },
                onError: () => {},
                onEnd: () => {
                    this.loadingDetalhes = false;
                },

            }, pacoteId)
        },

        getSeguroInfo() {
            console.log('compra: ', this.pacotes)
            for (let i=0; i < this.pacotes.length; i++) {
                if (this.pacotes[i].pacote_id === this.pacoteId) {
                    console.log('entrou')
                    console.log('quantidade: ', this.pacotes[i].produtos[0].quantidade)
                    this.quantidadeBeneficiarios = this.pacotes[i].produtos[0].quantidade
                    // this.tituloSeguro = this.pacotes[i].produtos[0].
                }
            }
        },

        abrirModalAvisoBeneficiario() {
            this.getQuantidadeSeguradosCadastrados(this.carrinhoId)
            this.$modal.show('modal-aviso-beneficiario')
        },

        continuarCadastro() {
            this.$modal.hide('modal-aviso-beneficiario')
        },

        voltar() {
            console.log('entrou')
            // this.$router.push({ name: "meus-pedidos" })
            setTimeout(function () {
                location.reload()
            }, 700);
        },

        limparFormulario() {
            this.segurado.nome = ''
            this.segurado.email = ''
            this.segurado.telefone = ''
            this.segurado.cpf = ''
            this.segurado.placa = ''
            this.segurado.modelo = ''
            this.segurado.dataDeNascimento = ''
            this.segurado.cep = ''
            this.segurado.rua = ''
            this.segurado.numero = ''
            this.segurado.complemento = ''
            this.segurado.bairro = ''
            this.segurado.cidade = ''
            this.segurado.estado = ''
        },

        finalizar() {
            if (!this.$refs.form.validate() || !this.valid) {
                return;
            }

            this.getSeguroInfo()

            console.log('quantidade: ', this.quantidadeBeneficiarios)

            // this.abrirModalAvisoBeneficiario()

            console.log('tipo de formulário: ', this.tipoDeFormulario)

            const segurado = {
                "carrinhoId": `${this.carrinhoId}`,
                "pacoteId": this.pacoteId,
                "formularioTipo": this.tipoDeFormulario,
                "usuarioId": localStorage.getItem("id_usuario"),
                "nome": this.segurado.nome,
                "dataNascimento": this.segurado.dataDeNascimento,
                "cpf": this.segurado.cpf.match(/\d/g).join(""),
                "placa": this.segurado.placa.replace("-", ""),
                "modelo": this.segurado.modelo,
                "email": this.segurado.email,
                "telefone": this.segurado.telefone,
                "rua": this.segurado.rua,
                "bairro": this.segurado.bairro,
                "cep": this.segurado.cep.replace("-", ""),
                "complemento": this.segurado.complemento,
                "cidade": this.segurado.cidade,
                "estado": this.segurado.estado,
            }

            console.log('segurado: ', segurado)

            const usuarioService = new UsuarioService()

            usuarioService.cadastrarSegurado({
                onSucess: (status) => () => {
                    if (status === 200) {
                        this.getQuantidadeSeguradosCadastrados(this.carrinhoId)
                        this.$toast.open({
                            position: 'top-right',
                            message: 'Usuário cadastrado com sucesso!',
                            type: 'success',
                            // all of other options may go here
                        });
                        // this.limparFormulario()
                        
                        // setTimeout(function () {
                        //     location.reload()
                        // }, 1000);
                        this.desabilitarBotao()
                        this.limparFormulario()
                    }

                    else if (status === 400 || status === 500) {
                        // this.$router.push({ name: "login" });
                        this.$toast.open({
                            position: 'top-right',
                            message: 'Não foi possível cadastrar o usuário!',
                            type: 'error',
                            // all of other options may go here
                        });
                    }
                },
                onError: (error) => {
                    console.log('error: ', error)
                },
                onEnd: (error) => {
                    console.log('error: ', error)
                },
            }, segurado)

            // setTimeout(function () {
            //     location.reload()
            // }, 700);
        },
    }
}
</script>

<style>
    .container-modal {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 10px;
    }

    .botao-modal-aviso {
        background-color: #3178ac !important;
    }

    .container-modal-beneficiario {
        display: flex;
        justify-content: center;
        padding: 6rem;
    }

    .container-card {
        padding: 4rem;
        width: 60%;
    }

    .container-botoes {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
    }

    .botao-proximo {
        background-color: #3178ac !important;
    }

    .botao-voltar {
        background-color: #3178ac !important;
    }

    .botao-finalizar {
        background-color: #3178ac !important;
    }

    .informativo {
        /* background-color: red; */
        margin-top: 0.8rem;
        width: 100%;
        color: #3178ac !important;
    }

    @media (max-width: 600px) {
        .container-modal-beneficiario {
            padding: 0;
            
        }

        .container-card {
            padding: 1rem;
            width: 100%;
        }
        
        .container-botoes {
            flex-direction: column;
            padding: 0.2rem;
            justify-content: space-evenly;
        }

        .botao-finalizar {
            margin: 0.5rem;
        }

        .botao-voltar {
            margin: 0.5rem;
        }
    }
</style>