<template>
  <div>
    <div class="d-flex">
      <div class="col-9 text-left block-select">
        <p class="tit">Por que escolher os seguros da StartFort</p>
        <div class="block mt-10">
          <div class="bg-meio">
            <v-icon class="icon">mdi-laptop</v-icon>
            <p class="font">Comodidade para comprar 100% online</p>
          </div>
          <div class="bg-meio">
            <v-icon class="icon">mdi-shield-lock</v-icon>
            <p class="font">Cotação com as maiores seguradoras do Brasil</p>
          </div>
          <div class="bg-meio">
            <v-icon class="icon">mdi-hours-24</v-icon>
            <p class="font">Assistência 24h para emergências</p>
          </div>
        </div>
      </div>
      <div class="col-3 space">
        <img src="@/assets/images/clip.png" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
};
</script>

<style scoped>
.block-select {
  padding: 0rem 14rem 0rem 11rem;
}
.space img {
  margin: 1rem 0rem 0rem -20rem;
  width: 23rem;
}
.bannercall {
  text-align: right;
  background-size: cover;
  padding: 3rem 11rem 2rem 0rem;
}
.space {
  background-color: #6ca9d7;
  height: 34rem;
}
.bg-meio .icon {
  margin-top: 1rem;
  color: #fff;
  font-size: 4rem;
}

.bg-meio {
  height: 16rem;
  max-width: 12rem !important;
  padding: 1rem 0.8rem 0rem 0.8rem;
}
.bg-meio .font {
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
}
.img-carousel {
  width: 85%;
}
@media (max-width: 600px) {
  .space img {
    margin: 1rem 0rem 0rem 0em;
  }
  .bannercall[data-v-e404f1bc] {
    text-align: center;
    background-size: 223rem;
    padding: 3rem 0rem 2rem 0rem;
  }
  .bg-meio {
    max-width: 100% !important;
    height: 12rem;
    margin-bottom: 1rem;
  }
}
</style>