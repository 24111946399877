import HTTPService from '@/services/http_service.js'

export default class AuthService extends HTTPService {
    resource = 'auth/cliente'
    login(responseFunctions, usuario) {
        this.request(responseFunctions, 'POST', this.resource, usuario)
    }

    register() {

    }
}