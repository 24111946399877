var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('header-geral')],1),_c('div',{staticClass:"container"},[_c('menu-loja'),_c('h2',{staticClass:"appColor-blueblack tit text-left mt-12 ml-5"},[_vm._v("Minha conta")])],1),(_vm.loading)?_c('div',{staticClass:"container-carregando"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"#3178ac"}})],1):_c('div',[(_vm.segurosComprados.length === 0)?_c('div',{staticClass:"container-seguro"},[_c('div',{staticClass:"tab-pane fade show active text-left",attrs:{"id":"nav-home","role":"tabpanel","aria-labelledby":"nav-home-tab"}},[_c('h3',{staticClass:"font appColor-blue mb-10"},[_vm._v(" Parece que você ainda não possui um seguro. ")]),_c('p',{staticClass:"font appColor-blue mb-10"},[_vm._v("Escolha um dos nossos produtos")]),_c('v-btn',{staticClass:"appBG-primary px-9 appColor-white",attrs:{"href":"../servicos"}},[_vm._v("Pesquisar")])],1)]):_c('div',[(!_vm.mostrarFormSegurado && !_vm.mostrarDetalhes)?[_vm._l((_vm.segurosComprados),function(seguro){return [(_vm.permitirSeguro(seguro.compra_status))?_vm._l((seguro.pacotes),function(pacote,index){return _c('div',{key:index},[(!_vm.mostrarDetalhes)?_c('div',{staticClass:"container-info mt-8"},[_c('v-card',{staticClass:"content-info mb-6",attrs:{"elevation":"3","outlined":""}},[_c('v-card',{staticClass:"seguro-info mb-1",attrs:{"elevation":"1"}},[_c('h4',[_vm._v(" "+_vm._s(pacote.pacote_nome)+" "+_vm._s(_vm.dataCompra(seguro.compra_data))+" "),(
                          seguro.compra_status.toLowerCase() ==
                            'awaiting_table'
                        )?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"yellow"}},[_vm._v(" Em analise ")]):_vm._e()],1),_vm._l((pacote.produtos),function(produtos,index){return _c('div',{key:index},[_c('strong',{staticStyle:{"color":"#3178ac"}},[_vm._v(_vm._s(produtos.quantidade)+" Beneficiários")])])})],2),_c('div',{staticClass:"container-botoes mt-2"},[_c('v-btn',{staticClass:"botao-info",on:{"click":function($event){return _vm.getProdutoInfo(pacote.pacote_id)}}},[_c('strong',{staticStyle:{"color":"#fff","text-transform":"capitalize"}},[_vm._v("Mais informações")])]),(_vm.verificarStatus(seguro.compra_status))?_c('v-btn',{staticClass:"botao-cadastrar",on:{"click":function($event){return _vm.cadastrarSegurado(
                          seguro.carrinho_id,
                          pacote.pacote_id,
                          seguro.pacotes
                        )}}},[_c('v-icon',{staticClass:"mr-1",staticStyle:{"color":"#3178ac"}},[_vm._v("mdi-account-plus")]),_c('strong',{staticStyle:{"color":"#3178ac","font-size":"12px"}},[_vm._v("CADASTRAR BENEFICIÁRIOS")])],1):_vm._e()],1)],1)],1):_vm._e()])}):_vm._e()]})]:_vm._e(),(_vm.mostrarFormSegurado)?_c('div',[_c('modal-beneficiario',{attrs:{"pacoteId":_vm.pacoteId,"carrinhoId":_vm.carrinhoId,"pacotes":_vm.pacotes,"tipoDeFormulario":_vm.tipoFormulario}})],1):_vm._e(),(!_vm.mostrarFormSegurado && _vm.mostrarDetalhes)?_c('div',{staticClass:"container-detalhes"},[_c('v-card',{staticClass:"conteudo-detalhes p-2 text-center",attrs:{"elevation":"3"}},[(_vm.loadingDetalhes)?_c('v-progress-circular',{staticStyle:{"align-self":"center"},attrs:{"indeterminate":"","color":"#3178ac"}}):_c('div',{staticClass:"text-start"},[_c('h2',{staticStyle:{"font-size":"16px"}},[_vm._v(_vm._s(_vm.tituloSeguro))]),_c('p',[_vm._v(_vm._s(_vm.descricaoSeguro))])])],1),_c('div',{staticClass:"container-botoes-externos mt-8"},[_c('v-btn',{staticClass:"botao-voltar",on:{"click":_vm.fecharDetalhes}},[_c('strong',{staticStyle:{"color":"#fff","text-transform":"capitalize"}},[_vm._v("Voltar")])]),_c('v-btn',{staticClass:"botao-cancelar"},[_c('strong',{staticStyle:{"color":"#fff","text-transform":"capitalize"}},[_vm._v("Cancelar seguro")])])],1)],1):_vm._e()],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }