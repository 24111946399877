<template>
  <div>
    <div class="float">
      <a
        href="https://api.whatsapp.com/send?phone=5585988882489"
        target="blank"
      >
        <img class="float-img" src="@/assets/images/whatsapp.png" alt="" />
      </a>
    </div>
    <div id="slidefooter" class="container mt-10">
      <div class="text-left">
        <p class="tit tit-slide">
          Trabalhamos com as maiores seguradoras do Brasil
        </p>
      </div>
      <carousel :perPage="3">
        <slide>
          <v-card>
            <img class="img-carousel" src="@/assets/images/parcerias1.jpg" />
          </v-card>
        </slide>
        <slide>
          <v-card>
            <img class="img-carousel" src="@/assets/images/parcerias2.jpg" />
          </v-card>
        </slide>
        <slide>
          <v-card>
            <img class="img-carousel" src="@/assets/images/parcerias3.jpg" />
          </v-card>
        </slide>
        <slide>
          <v-card>
            <img class="img-carousel" src="@/assets/images/parcerias4.jpg" />
          </v-card>
        </slide>
        <slide>
          <v-card>
            <img class="img-carousel" src="@/assets/images/parcerias5.jpg" />
          </v-card>
        </slide>
        <slide>
          <v-card>
            <img class="img-carousel" src="@/assets/images/parcerias6.jpg" />
          </v-card>
        </slide>
        <slide>
          <v-card>
            <img class="img-carousel" src="@/assets/images/parcerias7.jpg" />
          </v-card>
        </slide>
        <slide>
          <v-card>
            <img class="img-carousel" src="@/assets/images/parcerias8.jpg" />
          </v-card>
        </slide>
        <slide>
          <v-card>
            <img class="img-carousel" src="@/assets/images/parcerias9.jpg" />
          </v-card>
        </slide>
        <slide>
          <v-card>
            <img class="img-carousel" src="@/assets/images/parcerias10.jpg" />
          </v-card>
        </slide>
        <slide>
          <v-card>
            <img class="img-carousel" src="@/assets/images/parcerias11.jpg" />
          </v-card>
        </slide>
      </carousel>
    </div>
    <v-footer class="footerBG pt-14 pb-16" padless>
      <div class="container">
        <div class="bloco">
          <div class="col-4 form">
            <Form />
          </div>
          <div class="col-7 row">
            <div class="col-lg-5">
              <div class="logofooter text-left d-block ml-10">
                <!-- <router-link to="/">-->
                <h3 class="text-uppercase appColor-primary text-left">
                  Mapa do site
                </h3>
                <a href="/"><p>Home</p></a>
                <a href="../sobre"><p>Sobre a empresa</p></a>
                <a href="../servicos"><p>Serviços</p></a>
                <a href="#form"><p>Solicitação de Proposta</p></a>
                <!-- <a href="#enseada"><p>Trabalhe conosco</p></a> -->
                <a href="#form"><p>Contato</p></a>
                <a href="https://sistema.starfortseguros.com.br/login"
                  ><p>Área Administrativa</p></a
                >
                <!--</router-link>-->
              </div>
              <img class="imgfooter" src="@/assets/images/logobranca.png" />
            </div>
            <div class="col-lg-6">
              <div class="logofooter text-left appColor-primary">
                <h3 class="text-uppercase text-left">Onde estamos</h3>
                <div class="d-flex">
                  <div>
                    <v-icon class="icon">mdi-map-marker</v-icon>
                  </div>
                  <div>
                    <p>
                      R. Des. Lauro Nogueira, 1500 - Papicu, Fortaleza - CE,
                      60175-055
                    </p>
                  </div>
                </div>
                <div class="d-flex">
                  <div>
                    <v-icon class="icon">mdi-phone</v-icon>
                  </div>
                  <div>
                    <p>+55 (85) 98888.2489</p>
                  </div>
                </div>
                <div class="d-flex">
                  <div>
                    <v-icon class="icon">mdi-email</v-icon>
                  </div>
                  <div>
                    <p>starfortseguros@gmail.com</p>
                  </div>
                </div>
                <h3 class="text-uppercase text-left mt-10">
                  Acompanhe nossas redes sociais
                </h3>
                <div class="d-flex align-items-center">
                  <a
                    href="https://www.facebook.com/starfortseguros/"
                    target="_blank"
                    ><v-icon class="appColor-primary mr-2"
                      >mdi-facebook</v-icon
                    ></a
                  >
                  <a
                    href="https://www.instagram.com/starfort_seguros/"
                    target="_blank"
                    ><v-icon class="appColor-primary mr-2"
                      >mdi-instagram</v-icon
                    ></a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-footer>
    <div class="subfooter appBG-bluedark d-flex justify-content-center">
      <a href="https://prolins.com.br" target="_blank">
        <v-img src="@/assets/images/logo_prolins.png" max-width="80px"></v-img>
        <!-- <v-img
          src="@/assets/images/logo-prolins-branco.png"
          max-width="140px"
        ></v-img> -->
      </a>
    </div>
  </div>
</template>

<script>
import UnidadeService from "../../services/unidades_service";
import Form from "../home/form_app.vue";

export default {
  components: {
    Form,
  },
  data: () => ({
    unidades: [],
    unidade_atual: 0,
  }),

  beforeMount() {
    this.getUnidades();

    setInterval(() => {
      if (this.unidade_atual + 1 == this.unidades.length) {
        this.unidade_atual = 0;
      } else {
        this.unidade_atual++;
      }
    }, 1500);
  },

  methods: {
    getUnidades() {
      this.loading = true;
      const unidadeService = new UnidadeService();
      this.unidades = [];
      const onSucess = (status) => (body) => {
        if (status === 200) {
          this.unidades = body.data.unidades;
        } else {
          console.log(body);
        }
      };
      const onError = (error) => {
        console.log(error);
      };
      const onEnd = () => {
        this.loading = false;
      };

      unidadeService.getList({ onSucess, onError, onEnd });
    },
  },
};
</script>

<style scoped>
.imgfooter {
  margin-top: 11rem;
  margin-left: 2rem;
}
.icon {
  color: #fff;
  font-size: 2rem !important;
}
.form {
  background-color: #fff;
  box-shadow: 0px -4px 47px #59656d3d;
  margin-top: -7rem;
}
h3,
.appColor-primary,
a {
  color: #fff !important;
}
i {
  font-size: 48px !important;
}
.subfooter {
  /* padding: 2rem 0; */
  padding: 1rem 0;
  background-color: #142166;
}

.itens-footer {
  list-style: none;
  padding-left: 0;
}
.tit-slide {
  font-size: 2rem;
}
.itens-footer li {
  text-align: left;
}

.itens-footer li a {
  color: #000;
}
.logofooter {
  font-weight: 600;
}

.float {
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 9999;
}
.float-img {
  width: 40px;
  height: 40px;
}
.float-img:hover {
  filter: contrast(150%);
}

@media (max-width: 600px) {
  .d-flex {
    display: flex !important;
  }
  .v-application .ml-10 {
    margin-left: 3px !important;
  }
}
</style>
