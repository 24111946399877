<template>
  <div>
    <div class="container container-detalhes">
      <Menu_loja />
    </div>
    <div class="bg-detalhes text-left">
      <div class="col-6">
        <div class="appBG-primary blockhead">
          <p class="tit appColor-white">Detalhes</p>
        </div>
        <p class="appColor-white linkhead font">
          <a href="/">Home</a> >> <a href="../servicos">Seguros</a>
        </p>
      </div>
    </div>

    <div style="background-color: red !important;">
      <v-dialog v-model="showModalCotacao" max-width="500px">
        <form-app :isModal="isModalCotacao" @fecharModal="fecharModal" />
      </v-dialog>
    </div>

    <div class="container">
      <div class="bloco">
        <div class="col-4">
          <div class="appBG-bluedark p-4">
            <div class="row appColor-white">
              <div class="col-3">
                <v-icon class="appColor-white phone" left> mdi-phone </v-icon>
              </div>
              <div class="col-9 text-left">
                <p>Entre em contato para mais informações</p>
                <strong class="">+55 (85) 98888.2489</strong>
              </div>
              <p class="text-left mt-5">
                Trabalhamos com as mais serias e conceituadas seguradoras do
                país, pois visamos sempre o melhor atendimento. Escolha o
                benefício ideal para você!
              </p>
              <v-btn
                class="btn mt-5 appBG-primary appColor-white w-100"
                href="#form"
              >
                Fale conosco
              </v-btn>
            </div>
          </div>
          <v-btn
            class="btn mt-5 appBG-bluedark appColor-white w-100"
            href="../sobre"
          >
            Conheça a StarFort
          </v-btn>
        </div>
        <div class="col-8">
          <v-img
            :src="produto.src"
            class="text-center"
            aspect-ratio="2"
            cover
          ></v-img>
          <h2 class="titles appColor-secondary text-left">
            {{ produto.titulo }}
          </h2>
          <!--p class="font font-info mb-5 text-left">
            {{ produto.prevencao }}
          </p-->
          <div class="d-flex row justify-content-star">
            <div class="mb-2 mx-4">
              <strong>
                Valor total:
                {{
                  getValorTotal()
                    | money({
                      prefix: "R$ ",
                      suffix: "",
                      thousands: ".",
                      decimal: ",",
                      precision: 2,
                    })
                }}
              </strong>
            </div>
            <div>
              <strong>
                Valor mensal:
                {{
                  (getValorTotal() / 12)
                    | money({
                      prefix: "R$ ",
                      suffix: "",
                      thousands: ".",
                      decimal: ",",
                      precision: 2,
                    })
                }}
              </strong>
            </div>
          </div>
          <h3 class="mt-5 md-5 text-left  border-bottom">
            Produtos no pacote:
          </h3>
          <div
            class="my-5 text-left border-bottom"
            v-for="produto in produtosArr"
            :key="produto.produto_id"
          >
            <h5 class="appColor-blue">
              <strong>{{ produto.produto }}</strong>
            </h5>
            <p>
              <strong>Descrição:</strong> {{ produto.prevencao }}<br />
              <strong>Observação:</strong> {{ produto.indicacao }}<br />
            </p>
          </div>
          <!--pre class="font font-info mb-5 text-left">{{
            produto.indicacao
          }}</pre-->
          <!-- <div class="text-left">
            <h4 class="appColor-secondary">Link de cotação:</h4>
            <a
              v-bind:href="produto.esquemaDoses"
              class="font font-info mb-5"
              target="_blank"
            >
              {{ produto.esquemaDoses.join("") }}
            </a>
          </div> -->
          <div class="mt-5">
            <v-btn
              class="appBG-blue appColor-white mt-2 mr-3"
              @click="adicionaraoCarrinho"
            >
              <v-icon left> mdi-cart </v-icon>
              <strong>Contratar agora</strong>
            </v-btn>

            <v-btn
              v-if="produto.esquemaDoses[0] === 'modal'"
              class="appBG-blue appColor-white mt-2 mr-3"
              @click="abrirModalCotacao"
            >
              <v-icon left> mdi-cart </v-icon>
              Solicitar Cotação
            </v-btn>

            <v-btn
              v-else
              class="appBG-blue appColor-white mt-2"
              :href="produto.esquemaDoses"
            >
              <v-icon left> mdi-cart </v-icon>
              Solicitar Cotação
            </v-btn>
          </div>
          <div class="text-right"></div>
        </div>
      </div>
    </div>
    <div>
      <detalhe-pacote />
    </div>
    <div class="mt-16">
      <footer-app />
    </div>
  </div>
</template>

<script>
import footerApp from "@/components/template/footer_app.vue";
import CarrinhoMixin from "@/mixins/carrinho_mixin.js";
import LojaService from "../services/loja_service.js";
import Mixin from "@/mixins/vuex_mixin.js";
import Menu_loja from "../components/template/header/navbar/menu_loja.vue";
import FormApp from "../components/home/form_app.vue";
import DetalhePacote from "../views/detalhe_pacote.vue";

export default {
  mixins: [CarrinhoMixin, Mixin],
  components: {
    DetalhePacote,
    Menu_loja,
    footerApp,
    FormApp,
  },
  data: () => ({
    showModalCotacao: false,
    isModalCotacao: false,
    quantidade: 1,
    produto: {
      esquemaDoses: [],
      prevencao: [],
      recomendacoes: [],
      indicacao: [],
    },
    produtosArr: [],
  }),
  beforeMount() {
    this.getProduto();
    if (
      this.$route.params.produtoId !== "" &&
      this.$route.params.produtoId !== undefined
    ) {
      this.produtoId = this.$route.params.produtoId;
    } else {
      this.$router.replace({ path: "/" });
    }
  },
  mounted() {
    this.produto.valorTotal = this.produto.valorAtual * this.quantidade;
  },
  watch: {
    quantidade(quantidade) {
      this.produto.valorTotal = this.produto.valorAtual * quantidade;
    },
  },
  methods: {
    abrirModalCotacao() {
      this.isModalCotacao = true;
      this.showModalCotacao = true;
    },

    fecharModal(value) {
      this.showModalCotacao = value;
      console.log("show modal cotação: ", this.showModalCotacao);
    },

    adicionaraoCarrinho() {
      let carrinho = {
        titulo: this.produto.titulo,
        valorTotal: this.produto.valorTotal,
        valorUnitario: this.produto.valorAtual,
        quantidade: this.quantidade,
        id: this.produto.produtoId,
      };

      this.$_ACTIONS_carrinho(carrinho);
      this.$_ACTIONS_showSnackbarMessage({
        message: "Adicionado ao carrinho",
        color: "sucess",
      });

      setTimeout(function() {
        location.reload();
      }, 700);
    },
    getValorTotal() {
      try {
        const valorTotal = this.produto.valorAtual;
        return parseFloat(valorTotal);
      } catch (e) {
        return 0;
      }
    },
    getProduto() {
      this.loading = true;
      const lojaService = new LojaService();

      const onSucess = (status) => (body) => {
        if (status === 200) {
          // this.totalList = body.data.pagination.num_rows;
          const produto = body.data.loja[0];
          console.log(body.data);
          this.produto = {
            produtoId: this.$route.params.produtoId,
            src: produto.foto ?? require("@/assets/images/servico.png"),
            //src: require("@/assets/images/produto.png"),
            titulo: produto.nome,
            descricao: "asd",
            valorAtual: produto.preco,
            valorTotal: produto.preco,
            prevencao: "asd",
            indicacao: "asd",
            recomendacoes: "asd",
            esquemaDoses: "asd",
          };
          this.produtosArr = produto.produtos;
          console.log("this.produtosArr");
          console.log(this.produtosArr);
          console.log("esquema doses: ", this.produto.esquemaDoses[0] === "");
        }
      };
      const onError = (error) => {
        console.log(error);
      };
      const onEnd = () => {
        this.loading = false;
      };

      lojaService.getOne(
        {
          onSucess,
          onError,
          onEnd,
        },
        this.$route.params.produtoId
        // itemsPerPage,
        // this.search,
        // this.options.ordenacao,
        // this.options.desc
      );
    },

    closeModalCotacao() {
      this.modalCotacao = false;
    },
  },
};
</script>

<style scoped>
.container-detalhes {
  position: relative;
}

.btn {
  text-transform: capitalize;
  padding-top: 15px !important;
  font-weight: 700;
  height: 48px !important;
}
.filtro {
  justify-content: space-around;
}
.phone {
  font-size: 4rem;
}
.como p {
  margin: 6px 1px 0px 8px;
}
.como {
  justify-content: space-between;
}
.font-info {
  font-size: 20px;
}
.benefic {
  margin-right: 1rem;
  padding: 3% !important;
}
.benef {
  border: 3px solid #aaaaaa;
  border-radius: 8px;
  width: 100%;
  color: #aaaaaa;
  margin-top: 1rem;
  font-size: 22px;
}
.benef v-icon {
  color: #aaaaaa !important;
}
.barra {
  border: 1px solid #707070;
}
.bloc-info {
  box-shadow: 0px 6px 16px #00000029;
  padding: 2%;
  margin-bottom: 2rem;
  border-radius: 13px;
  margin-top: 1%;
}
.icon {
  color: #000000 !important;
  background-color: #e8e8e8;
  padding: 12px;
  border-radius: 31px;
}
.btnfiltro {
  background-color: #2b3a8f;
  border: 1px solid #2b3a8f;
}

.v-icon--disabled {
  border: 1px solid #cdcdcd;
}
.como-funciona,
.quadro-branco {
  border-radius: 0.8rem;
}

.titles {
  margin-top: 2.3rem;
  font-size: 1.5rem;
}

.modal-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vh;
  min-height: 100vh;
  overflow-x: hidden;
}

.detalhes-container {
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  overflow: hidden;
}
</style>
