<template>
  <div>
    <div>
      <Header_geral />
    </div>
    <div>
      <Menu_int />
    </div>
    <div class="d-flex container labels">
      <div class="col-2">
        <h3 class="titles appColor-blue">PACOTES</h3>
      </div>
      <!-- LOCAL -->
      <div class="col-5 d-flex text-left">
        <div class="bloco1">
          <p>Origem</p>
          <v-autocomplete
            :items="items"
            label="Insira seu local de origem"
            outlined
            color="#14457c"
          >
          </v-autocomplete>
        </div>
        <div class="bloco2">
          <p>Destino</p>
          <v-autocomplete
            :items="items2"
            label="Insira seu local de destino"
            outlined
            color="#14457c"
          >
          </v-autocomplete>
        </div>
      </div>
      <!-- CALENDAR -->
      <div class="col-3 d-flex text-left">
        <div class="bloco3">
          <p>Datas</p>
          <v-text-field
            v-model="dateRangeText"
            label="Ida ~ Volta"
            readonly
            color="#14457c"
            @click="abrir = !abrir"
          ></v-text-field>
        </div>
      </div>
      <!-- HELP -->
      <div class="col-2 d-flex">
        <div>
          <v-icon class="appColor-blue iconhelp"> mdi-help</v-icon>
        </div>
        <div>
          <h4 class="appColor-blue font" href="#">Precisa de ajuda?</h4>
        </div>
      </div>
    </div>
    <div>
      <div>
        <v-date-picker
          v-model="dates"
          v-if="abrir"
          color="#14457c"
          range
        ></v-date-picker>
      </div>
      <div class="d-flex container" v-if="dados[0].data.length > 0">
        <v-card class="col-3">
          <!--FILTRO-->
          <div class="d-flex font">
            <div class="text-left col-6">
              <a class="appColor-blue fontw">
                <v-icon class="appColor-blue">
                  mdi-filter-variant
                </v-icon>
                Filtros</a
              >
            </div>
            <div class="text-right col-6">
              <a class="appColor-blue fontw">Limpar filtro</a>
            </div>
          </div>
          <div>
            <div class="blocfilter font">
              <p>Preço</p>
            </div>
            <div class="font text-left mt-3">
              <p>de R$ até R$</p>
              <v-slider max="84" min="-45"></v-slider>
              <!--VALOR FILTRO-->
              <div class="d-flex">
                <div>
                  <p>Início</p>
                  <v-text-field
                    class="valor"
                    prefix="R$"
                    outlined
                  ></v-text-field>
                </div>
                <div>
                  <p>Final</p>
                  <v-text-field
                    class="valor"
                    prefix="R$"
                    outlined
                  ></v-text-field>
                </div>
              </div>
            </div>
            <div class="blocfilter font">
              <p>Refeições</p>
            </div>
            <div v-for="dado in dados[0].refeicao" :key="dado.id">
              <v-checkbox color="orange" :label="dado.name" hide-details>
              </v-checkbox>
            </div>
            <div class="blocfilter font mt-3">
              <p>Comodidades</p>
            </div>
            <div v-for="dado in dados[0].data" :key="dado.id">
              <v-checkbox color="orange" :label="dado.comodidades" hide-details>
              </v-checkbox>
            </div>
            <div class="blocfilter font mt-3">
              <p>Nome do Hotel</p>
            </div>
            <v-text-field label="Nome do Hotel"></v-text-field>
          </div>
        </v-card>
        <div class="col-9">
          <template> </template>
          <v-card class="d-flex mt-5" v-for="(produto, i) in produtos" :key="i">
            <div class="col-3">
              <img class="imgproduct" :src="produto.src" />
            </div>
            <div class="col-9 d-flex">
              <div class="col text-left">
                <h3>{{ produto.titulo }}</h3>
                <a href=""
                  ><h4 class="appColor-blue font">
                    {{ produto.descricao }}
                  </h4></a
                >
              </div>
              <div class="col text-right">
                <h4 class="font appColor-blue">
                  {{
                    produto.valorAtual
                      | money({
                        prefix: "",
                        suffix: "",
                        thousands: ".",
                        decimal: ",",
                        precision: 0,
                      })
                  }}
                </h4>
                <p class="font appColor-blue">
                  em até 12x de R$ 187,29 s/ juros no cartão de crédito
                </p>
                <v-btn
                    class="appBG-primary appColor-white mt-2 btnslide" :href="produto.href"
                    @click="detalhesProduto(produto.produtoId)"
                    >
                    Saiba mais
                    </v-btn>
              </div>
            </div>
          </v-card>
          <v-pagination v-model="page" :length="0"></v-pagination>
        </div>
      </div>
    </div>
    <div>
      <footerApp />
    </div>
  </div>
</template>
<script>
import Header_geral from "../components/template/header/header_geral.vue";
import Menu_int from "../components/template/header/navbar/menu_int.vue";
import footerApp from "@/components/template/footer_app.vue";
import dados from "./../data/data.json";
import LojaService from "@/services/loja_service";

export default {
  mounted() {
    this.getProdutos();
  },
  data() {
    return {
      headers: [
        {
          text: "Dessert (100g serving)",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Calories", value: "calories" },
        { text: "Fat (g)", value: "fat" },
        { text: "Carbs (g)", value: "carbs" },
        { text: "Protein (g)", value: "protein" },
        { text: "Iron (%)", value: "iron" },
      ],
      pacotes: [
        {
          name: "ata",
          calories: 159,
          fat: 6.0,
          carbs: 24,
          protein: 4.0,
          iron: "1%",
        },
        {
          name: "Ice cream sandwich",
          calories: 237,
          fat: 9.0,
          carbs: 37,
          protein: 4.3,
          iron: "1%",
        },
        {
          name: "Eclair",
          calories: 262,
          fat: 16.0,
          carbs: 23,
          protein: 6.0,
          iron: "7%",
        },
        {
          name: "Cupcake",
          calories: 305,
          fat: 3.7,
          carbs: 67,
          protein: 4.3,
          iron: "8%",
        },
        {
          name: "Gingerbread",
          calories: 356,
          fat: 16.0,
          carbs: 49,
          protein: 3.9,
          iron: "16%",
        },
        {
          name: "Jelly bean",
          calories: 375,
          fat: 0.0,
          carbs: 94,
          protein: 0.0,
          iron: "0%",
        },
        {
          name: "Lollipop",
          calories: 392,
          fat: 0.2,
          carbs: 98,
          protein: 0,
          iron: "2%",
        },
        {
          name: "Honeycomb",
          calories: 408,
          fat: 3.2,
          carbs: 87,
          protein: 6.5,
          iron: "45%",
        },
        {
          name: "Donut",
          calories: 452,
          fat: 25.0,
          carbs: 51,
          protein: 4.9,
          iron: "22%",
        },
        {
          name: "KitKat",
          calories: 518,
          fat: 26.0,
          carbs: 65,
          protein: 7,
          iron: "6%",
        },
      ],
      produtos: [],
      dados: dados,
      items: ["Jericoacoara", "Canoa Quebrada", "Fortaleza", "Cumbuco", "Lagoinha"],
      items2: ["Jericoacoara", "Canoa Quebrada", "Fortaleza", "Cumbuco", "Lagoinha"],
      dates: [
        new Date().toISOString().substr(0, 10),
        new Date().toISOString().substr(0, 10),
      ],
      abrir: false,
    };
  },
  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
  },

  components: {
    Header_geral,
    Menu_int,
    footerApp,
  },

  watch: {},
  methods: {
    getProdutos() {
      this.loading = true;
      const lojaService = new LojaService();
      this.produtos = [];
      const onSucess = (status) => (body) => {
        if (status === 200) {
          console.log(body.data);

          this.produtos = body.data.loja.map((pack) => ({
            src: require("@/assets/images/produto.png"),
            titulo: pack.nome,
            // junta o titulo dos produtos
            descricao: pack.produtos.reduce(
              (acc, p) => acc + ` - ${p.produto}`,
              ""
            ),
            promocao: true,
            // valorAnterior: "R$ 350",

            valorAtual: pack.preco,
            parcelado: false,
            quantidade_parcelas: "",
            // preco_parcelado: "",
            href: `/detalhes-produto/${pack.pacote_id}`,
          }));
          this.produtos = this.produtos.slice(0, 4);
          //this.produtos.map( (p, i) => this.getProdutos(p, i))
        } else {
          // console.log(body);
        }
      };
      const onError = (error) => {
        console.log(error);
      };
      const onEnd = () => {
        this.loading = false;
      };
      lojaService.getListMostWanted({ onSucess, onError, onEnd });
    },
    detalhesProduto(produtoId) {
      this.$router.replace({
        name: "detalhes-produto",
        params: { produtoId: produtoId },
      });
    },
  },
  getProduto(produto, i) {
    this.loading = true;
    const lojaService = new LojaService();

    const onSucess = (status) => (body) => {
      if (status === 200) {
        // this.totalList = body.data.pagination.num_rows;
        const produtoReq = body.data.loja[0];
        console.log(body.data);
        produto = {
          prevencao: produtoReq.produtos
            .map((p) => p.prevencao)
            .filter((p) => p != null && p != ""),
          indicacoes: produtoReq.produtos
            .map((p) => p.indicacoes)
            .filter((p) => p != null),
          recomendacoes: produtoReq.produtos
            .map((p) => p.recomendacoes)
            .filter((p) => p != null),
          contraindicacoes: produtoReq.produtos
            .map((p) => p.contra_indicacoes)
            .filter((p) => p != null),
          esquemaDoses: produtoReq.produtos
            .map((p) => [p.nome, p.esquema_doses])
            .filter(
              ([nome, esquema_doses]) => nome != null && esquema_doses != null
            )
            .map(([nome, esquema_doses]) => `${nome}: ${esquema_doses}`)
            .reduce((acc, p) => acc + p, ""),
          ...produto,
        };

        this.produtos[i] = produto;
      }
    };
    const onError = (error) => {
      console.log(error);
    };
    const onEnd = () => {
      this.loading = false;
    };

    lojaService.getOne(
      {
        onSucess,
        onError,
        onEnd,
      },
      produto.id
      // itemsPerPage,
      // this.search,
      // this.options.ordenacao,
      // this.options.desc
    );
  },

  filters: {},
};
</script>
<style scoped>
.valor {
  width: 91% !important;
}
.v-application .primary,
.v-application .primary.lighten-3 {
  background-color: #2b3a8f !important;
  border-color: #2b3a8f !important;
}

.blocfilter {
  background-color: #e8e8e8;
  text-align: left;
  color: #434343;
  padding: 3%;
}
.fontw {
  font-weight: 700;
}
.btnprod {
  height: 26%;
  font-size: 16px;
}
.imgproduct {
  width: 100%;
}
.iconhelp {
  border: 2px solid #2b3a8f;
  border-radius: 14px;
  padding: 1%;
}
.labels,
label {
  color: #2b3a8f;
  font-weight: 600;
}
.bloco1 {
  border: 1px solid #707070;
  border-radius: 8px 0px 0px 8px;
  opacity: 1;
  padding: 2% 2% 0% 2%;
}
.bloco2 {
  border: 1px solid #707070;
  border-radius: 0px 8px 8px 0px;
  opacity: 1;
  padding: 2% 2% 0% 2%;
}
.bloco3 {
  border: 1px solid #707070;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  padding: 2% 2% 0% 2%;
}
.v-picker {
  margin: -1rem -27rem -24rem 1rem;
  z-index: 2;
}
</style>
