import HTTPService from '@/services/http_service.js'

export default class AgendamentoService extends HTTPService {
    resource = 'agendamento'

    desmarcarAgendamento(responseFunctions, idAgendamento) {
        this.request(responseFunctions, 'POST', `${this.resource}/${idAgendamento}/desmarcar`)
    }

    register(responseFunctions, agendamento) {
        this.jsonRequest(responseFunctions, 'POST', this.resource, agendamento)
    }

    getList(responseFunctions) {
        this.jsonRequest(responseFunctions, 'GET', this.resource)
    }

    getListPendentes(responseFunctions) {
        this.jsonRequest(responseFunctions, 'GET', 'agendamentos-cliente')
    }
    
}