<template>
  <div class="container">
    <div class="d-flex">
      <div class="col-5 text-left">
        <p class="tit">Conheça nossas soluções</p>
      </div>
      <!-- <div class="col-7 text-right">
        <v-btn class="btncallblue mt-5" href="../servicos"> Ver mais </v-btn>
      </div> -->
    </div>
    <div class="bloco mt-5 text-left">
      <div class="col-4 font" v-for="(produto, i) in produtos" :key="i">
        <div>
          <v-img
            :src="produto.src"
            class="text-center w-100"
            aspect-ratio="2"
            style="padding-bottom: 7%"
            cover
            position="20% 34%"
          ></v-img>
        </div>
        <div class="p-3">
          <p class="appColor-secondary prod-tit">{{ produto.titulo }}</p>
          <!-- <p class="appColor-secondary d-inline-block text-truncate">
            {{ produto.descricao }}
          </p> -->
          <!-- <p class="appColor-gray">A partir de</p> -->
          <p class="valor appColor-blue prod-tit">
            {{ formatPrice(produto.valorAtual / 12) }} por mês
          </p>
          <v-btn
            class="mt-2 btn-prod"
            :href="produto.href"
            @click="detalhesProduto(produto.produtoId)"
          >
            <v-icon> mdi-cart </v-icon>
            Comprar
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LojaService from "@/services/loja_service";
//import axios from 'axios'

export default {
  components: {},
  mounted() {
    this.getList();
  },
  data: () => {
    return {
      produtos: [],
      dates: [
        new Date().toISOString().substr(0, 10),
        new Date().toISOString().substr(0, 10),
      ],
      abrir: false,
    };
  },
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return "R$ " + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    getList() {
      this.loading = true;
      this.produtos = [];
      /*axios.get('https://www.starfortseguros.com.br/api/public/loja').then(res =>{
        console.log(res)
      }).catch(e => console.log(e))*/
      const lojaService = new LojaService();
      const onSucess = (status) => (body) => {
        if (status === 200) {
          console.log(body.data.loja);
          this.produtos = body.data.loja.map((pack) => ({
            src: pack.foto ?? require("@/assets/images/servico.png"),
            titulo: pack.nome,
            // junta o titulo dos produtos
            descricao:
              "Teste" /* pack.produtos.reduce(
              (acc, p) => acc + ` - ${p.produto}`,
              ""
            )*/,
            // valorAnterior: "R$ 350",
            valorAtual: pack.preco,

            // preco_parcelado: "",
            href: `/detalhes-produto/${pack.pacote_id}`,
          }));
          console.log(this.produtos);
        } else {
          console.log(body);
        }
      };
      const onError = (error) => {
        console.log(error);
      };
      const onEnd = () => {
        this.loading = false;
      };
      lojaService.getAll(onSucess, onError, onEnd, 1, 9, "", 1, 1);
      console.log(this.produtos);
    },
    detalhesProduto(produtoId) {
      this.$router.replace({
        name: "detalhes-produto",
        params: { produtoId: produtoId },
      });
    },
  },
};
</script>

<style scoped>
.prod-tit {
  font-weight: 800;
  font-size: 19px;
}

.btn-prod {
  text-transform: capitalize;
}
.p-3:hover .btn-prod {
  background-color: #3178ac !important;
  color: #fff;
}
.p-3:hover {
  box-shadow: 0px -4px 54px #81818159;
}

@media (max-width: 600px) {
  .bgsobre img {
    width: 70%;
    margin-left: 0rem;
  }
  .bgsobre {
    display: none;
  }
  .bannercall2 {
    text-align: center;
    display: block;
  }
  .textbc2 {
    padding: 3rem 2rem 3rem 2rem;
  }
}
</style>
