<template>
  <div>
    <div id="pacotes" class="tpac container">
      <div class="bloco">
        <div class="col-4 card-home appBG-primary seta">
          <div class="col-3">
            <img src="@/assets/images/checked.png" />
          </div>
          <div class="col-9">
            <b>Escolha o seguro</b>
            <p>
              Temos diversas soluções de seguro online para você!
            </p>
          </div>
        </div>
        <div class="col-4 card-home bg-meio seta">
          <div class="col-3">
            <img src="@/assets/images/login.png" />
          </div>
          <div class="col-9">
            <b>Faça login</b>
            <p>
              Acesse sua conta para aproveitar as vantagens dos nossos seguros.
            </p>
          </div>
        </div>
        <div class="col-4 card-home appBG-primary">
          <div class="col-3">
            <img src="@/assets/images/done.png" />
          </div>
          <div class="col-9">
            <b>Finalize a compra</b>
            <p>
              Contratação 100% digital sem sair de casa.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LojaService from "@/services/loja_service";

export default {
  data: () => {
    return {
      produtos: [],
      dates: [
        new Date().toISOString().substr(0, 10),
        new Date().toISOString().substr(0, 10),
      ],
      abrir: false,
    };
  },
  methods: {
    getList() {
      this.loading = true;
      const lojaService = new LojaService();
      this.produtos = [];
      const onSucess = (status) => (body) => {
        if (status === 200) {
          this.produtos = body.data.loja.map((pack) => ({
            src: require("@/assets/images/imgcar.png"),
            titulo: pack.nome,
            // junta o titulo dos produtos
            descricao: pack.produtos.reduce(
              (acc, p) => acc + ` - ${p.produto}`,
              ""
            ),
            // valorAnterior: "R$ 350",
            valorAtual: pack.preco,

            // preco_parcelado: "",
            href: `/detalhes-produto/${pack.pacote_id}`,
          }));
        } else {
          // console.log(body);
        }
      };
      const onError = (error) => {
        console.log(error);
      };
      const onEnd = () => {
        this.loading = false;
      };
      lojaService.getList({ onSucess, onError, onEnd });
    },
    detalhesProduto(produtoId) {
      this.$router.replace({
        name: "detalhes-produto",
        params: { produtoId: produtoId },
      });
    },
  },
  components: {},
};
</script>
<style scoped>
.slide .appColor-blue {
  font-weight: 700;
  font-size: 17px;
}
.btnslide,
.btnslide:focus {
  border: 0px;
  width: 100%;
  border-radius: 16px;
}
.valor {
  font-weight: 700;
}
.slidezin .slide {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 16px;
  flex-basis: auto;
  margin: 55px 0px 15px 15px;
  width: 170px;
}
.sub {
  font-size: 21px;
}
.tpac {
  text-align: left;
}
.tpac2 {
  text-align: right;
}
.image {
  padding-bottom: 20%;
}
.text-truncate {
  max-width: 149px;
}
.bloco {
  margin: 4rem 0rem 3rem 0rem;
}
.card-home {
  display: flex;
  color: #fff;
}
.card-home b {
  color: #fff;
  font-size: 23px;
}
.card-home p {
  font-size: 18px;
  line-height: 26px;
}
.seta::after {
  content: ">";
  background-color: #fff;
  color: #7bb2db;
  font-size: 21px;
  height: 25px;
  font-weight: bold;
  padding: 3%;
  padding-bottom: 11%;
  margin-left: -5px;
  margin-top: 3rem;
  z-index: 100000;
}
@media (max-width: 600px) {
  .tpac2 img,
  .tpac img {
    width: 100%;
  }
  .tpac,
  .tpac2 {
    text-align: center;
  }
}
</style>
