import HTTPService from '@/services/http_service.js'

export default class AplicacaoService extends HTTPService {
    resource = 'aplicacao'

    // update (responseFunctions, cliente) {
    //     this.jsonRequest(responseFunctions, 'POST', `${this.resource}/${cliente.id}`, cliente)
    // }
    
    getList (responseFunctions) {
        this.request(responseFunctions, 'GET', this.resource)
    }

} 