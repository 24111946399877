import HTTPService from '@/services/http_service.js'

export default class ClienteService extends HTTPService {
    resource = 'cliente'

    register (responseFunctions, cliente) {
        this.jsonRequest(responseFunctions, 'POST', this.resource, cliente)
    }

    update (responseFunctions, cliente) {
        this.jsonRequest(responseFunctions, 'POST', `${this.resource}/${cliente.id}`, cliente)
    }
    
    get (responseFunctions, idCliente) {
        const url = this.resource + '/' + idCliente
        this.request(responseFunctions, 'GET', url)
    }
} 