import { mapActions, mapGetters } from "vuex";

export default {
    computed: {
        ...mapGetters({
            $_GETTERS_carrinho: 'getCarrinho',
        }),
    },
    methods: {
        ...mapActions({
            $_ACTIONS_carrinho: 'setCarrinho',
            $_ACTIONS_carrinhoAtualizar: 'setCarrinhoAtualizar',
        }),
    }
}