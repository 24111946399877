<template>
  <div>
    <div>
      <header-geral />
    </div>

    <div class="container">
      <menu-loja />
      <h2 class="appColor-blueblack tit text-left mt-12 ml-5">Minha conta</h2>
    </div>

    <div class="container-carregando" v-if="loading">
      <v-progress-circular indeterminate color="#3178ac"></v-progress-circular>
    </div>

    <div v-else>
      <div class="container-seguro" v-if="segurosComprados.length === 0">
        <div
          class="tab-pane fade show active text-left"
          id="nav-home"
          role="tabpanel"
          aria-labelledby="nav-home-tab"
        >
          <h3 class="font appColor-blue mb-10">
            Parece que você ainda não possui um seguro.
          </h3>
          <p class="font appColor-blue mb-10">Escolha um dos nossos produtos</p>
          <!-- <a class="appBG-primary px-9 appColor-white" href="../detalhes-categoria">Pesquisar por Viagens</a> -->
          <v-btn class="appBG-primary px-9 appColor-white" href="../servicos"
            >Pesquisar</v-btn
          >
          <!-- <v-btn class="appBG-primary px-9 appColor-white" @click="logout">Pesquisar por Viagens</v-btn>  -->
        </div>
      </div>

      <div v-else>
        <!-- <modal 
                    name="modal-beneficiario" 
                    :adaptive="true"
                    :scrollable="true"
                    :height="auto"
                >
                    <modal-beneficiario/>
                </modal> -->
        <template v-if="!mostrarFormSegurado && !mostrarDetalhes">
          <template v-for="seguro in segurosComprados">
            <template v-if="permitirSeguro(seguro.compra_status)">
              <div v-for="(pacote, index) in seguro.pacotes" :key="index">
                <!-- <div v-if="mostrarFormSegurado">
              <modal-beneficiario
                :pacoteId="pacote_id"
                :carrinhoId="seguro_id"
                :pacotes="seguro.pacotes"
                :tipoDeFormulario="tipoFormulario"
              />
            </div> -->
                <div v-if="!mostrarDetalhes" class="container-info mt-8">
                  <v-card class="content-info mb-6" elevation="3" outlined>
                    <v-card class="seguro-info mb-1" elevation="1">
                      <h4>
                        {{ pacote.pacote_nome }}
                        {{ dataCompra(seguro.compra_data) }}
                        <v-chip
                          class="ma-2"
                          v-if="
                            seguro.compra_status.toLowerCase() ==
                              'awaiting_table'
                          "
                          color="yellow"
                        >
                          Em analise
                        </v-chip>
                      </h4>
                      <div
                        v-for="(produtos, index) in pacote.produtos"
                        :key="index"
                      >
                        <strong style="color: #3178ac;"
                          >{{ produtos.quantidade }} Beneficiários</strong
                        >
                      </div>
                    </v-card>
                    <div class="container-botoes mt-2">
                      <v-btn
                        class="botao-info"
                        @click="getProdutoInfo(pacote.pacote_id)"
                      >
                        <strong style="color: #fff; text-transform: capitalize;"
                          >Mais informações</strong
                        >
                      </v-btn>
                      <v-btn
                        class="botao-cadastrar"
                        @click="
                          cadastrarSegurado(
                            seguro.carrinho_id,
                            pacote.pacote_id,
                            seguro.pacotes
                          )
                        "
                        v-if="verificarStatus(seguro.compra_status)"
                      >
                        <v-icon style="color: #3178ac;" class="mr-1"
                          >mdi-account-plus</v-icon
                        >
                        <strong style="color: #3178ac; font-size: 12px;"
                          >CADASTRAR BENEFICIÁRIOS</strong
                        >
                      </v-btn>
                    </div>
                  </v-card>
                </div>
              </div>
            </template>
          </template>
        </template>
        <div v-if="mostrarFormSegurado">
          <modal-beneficiario
            :pacoteId="pacoteId"
            :carrinhoId="carrinhoId"
            :pacotes="pacotes"
            :tipoDeFormulario="tipoFormulario"
          />
        </div>
        <!-- <div v-else-if="!mostrarDetalhes" class="container-info mt-8">
          <v-card
            class="content-info mb-6"
            v-for="pacote in pacotes"
            :key="pacote.pacote_id"
            elevation="3"
            outlined
          >
            <v-card class="seguro-info mb-1" elevation="1">
              <h4>
                {{ pacote.pacote_nome }} -
                {{ segurosComprados[0].compra_status }}
              </h4>
              <div v-for="(produtos, index) in pacote.produtos" :key="index">
                <strong style="color: #3178ac;"
                  >{{ produtos.quantidade }} Beneficiários</strong
                >
              </div>
            </v-card>
            <div class="container-botoes mt-2">
              <v-btn
                class="botao-cadastrar"
                @click="cadastrarSegurado(pacote.pacote_id)"
              >
                <v-icon style="color: #3178ac;" class="mr-1"
                  >mdi-account-plus</v-icon
                >
                <strong style="color: #3178ac; font-size: 12px;"
                  >CADASTRAR BENEFICIÁRIOS</strong
                >
              </v-btn>

              <v-btn
                class="botao-info"
                @click="getProdutoInfo(pacote.pacote_id)"
              >
                <strong style="color: #fff; text-transform: capitalize;"
                  >Mais informações</strong
                >
              </v-btn>
            </div>
          </v-card>
        </div> -->

        <div
          class="container-detalhes"
          v-if="!mostrarFormSegurado && mostrarDetalhes"
        >
          <v-card elevation="3" class="conteudo-detalhes p-2 text-center">
            <v-progress-circular
              style="align-self: center;"
              v-if="loadingDetalhes"
              indeterminate
              color="#3178ac"
            ></v-progress-circular>
            <div class="text-start" v-else>
              <h2 style="font-size: 16px;">{{ tituloSeguro }}</h2>
              <p>{{ descricaoSeguro }}</p>
            </div>
          </v-card>

          <!-- <v-card
                        elevation="4"
                        class="conteudo-beneficiario p-2 mt-8"
                    >
                        <div style="text-align: left;">
                            <h2 style="font-size: 16px;">BeneficiÃ¡rios</h2>
                            <span>Joseph Oliver</span>
                        </div>
                        <div class="botoes-detalhes">
                            <v-btn class="botao-detalhe">
                                <strong class="botao-detalhe-titulo">Editar</strong>
                            </v-btn>
                            <v-btn class="botao-detalhe ml-3" id="botao-remover">
                                <strong style="" class="botao-detalhe-titulo">Remover</strong>
                            </v-btn>
                        </div>
                    </v-card> -->

          <div class="container-botoes-externos mt-8">
            <v-btn class="botao-voltar" @click="fecharDetalhes">
              <strong style="color: #fff; text-transform: capitalize;"
                >Voltar</strong
              >
            </v-btn>
            <v-btn class="botao-cancelar">
              <strong style="color: #fff; text-transform: capitalize;"
                >Cancelar seguro</strong
              >
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuLoja from "../components/template/header/navbar/menu_loja.vue";
import HeaderGeral from "@/components/template/header/header_geral.vue";
import ModalBeneficiario from "../components/modal-beneficiario/modal_beneficiario.vue";
import CompraService from "@/services/compras_service";
import LojaService from "@/services/loja_service";
// import UsuarioService from "@/services/loja_service";

export default {
  components: {
    MenuLoja,
    HeaderGeral,
    ModalBeneficiario,
  },

  data: () => ({
    segurosComprados: [],
    pacotes: [],
    carrinhoProdutos: [],
    totalCompra: 0,
    quantidadeCompra: 0,
    pacoteId: "",
    info: "",
    mostrarDetalhes: false,
    tituloSeguro: "",
    descricaoSeguro: "",
    mostrarFormSegurado: false,
    loading: true,
    loadingDetalhes: true,
    quantidadeBeneficiarios: "",
    carrinhoId: "",
    tipoFormulario: "",
    seguradosCadastrados: "",
  }),

  beforeMount() {
    if (!this.$store.state.token) {
      this.$router.push("/");
    }

    this.atualizarCarrinhoCompra();
  },

  mounted() {
    const compraService = new CompraService();
    compraService.getAll({
      onSucess: (status) => (body) => {
        if (status === 200) {
          console.log("entrou pedidos");
          this.segurosComprados = body.data.compras;
          console.log("this.segurosComprados");
          console.log(this.segurosComprados);
          this.carrinhoId = this.segurosComprados[0].carrinho_id;

          this.pacotes = this.segurosComprados[0].pacotes;
          // console.log('pedidos: ', this.pacotes)
        } else if (status === 400 || status === 500) {
          this.$router.push({ name: "login" });
        }
      },
      onError: () => {},
      onEnd: () => {
        this.loading = false;
      },
    });
  },

  filters: {
    fillLeft: function(value, qtd) {
      let v = value.toString();
      if (v.length < qtd) {
        return "0".repeat(qtd - v.length) + v;
      }

      return v;
    },

    formatData: function(data) {
      return data
        .substr(0, 10)
        .split("-")
        .reverse()
        .join("/");
    },
  },

  computed: {
    validationNome() {
      return [this.validationService.required("Informe o nome.")];
    },

    validationEmail() {
      return [
        this.validationService.required("Informe o e-mail."),
        this.validationService.isEmail(),
      ];
    },

    validationTelefone() {
      return [
        this.validationService.required("Informe o telefone."),
        this.validationService.isTelefone(),
      ];
    },

    validationCpf() {
      return [
        this.validationService.required("Informe o cpf."),
        this.validationService.isCPF(),
      ];
    },

    validationCnpj() {
      return [
        this.validationService.required("Informe o cnpj."),
        this.validationService.isCNPJ(),
      ];
    },

    validationCep() {
      return [
        this.validationService.required("Informe o cep."),
        this.validationService.isCEP(),
      ];
    },

    validationRua() {
      return [this.validationService.required("Informe a rua.")];
    },

    validationNumero() {
      return [this.validationService.required("Informe o numero.")];
    },

    validationBairro() {
      return [this.validationService.required("Informe o bairro.")];
    },

    validationCidade() {
      return [this.validationService.required("Informe a cidade.")];
    },

    validationEstado() {
      return [this.validationService.required("Informe o estado.")];
    },
  },

  methods: {
    dataCompra(dateTime) {
      if (dateTime == "0000-00-00 00:00:00" || dateTime == "") {
        return "";
      } else {
        let data = new Date(dateTime);
        return ` - ${data
          .getDate()
          .toString()
          .padStart(2, "0")}/${(data.getMonth() + 1)
          .toString()
          .padStart(2, "0")}/${data.getFullYear()}`;
      }
    },
    abrirModal() {
      this.$modal.show("modal-beneficiario");
    },
    // CONFIRMADO
    // CANCELADO
    // ERRO_MAXIMO_TENTATIVAS
    // ERRO_CANCELAMENTO
    // PAGO
    // SOLICITACAO_CANCELAMENTO
    // CANCELAMENTO_APROVADO
    // PAGAMENTO_PENDENTE
    // PENDENTE_ANTIFRAUDE
    // ERRO_DADOS
    // NAO_APROVADO_NO_ANTIFRAUDE
    // EXPIRADO
    permitirSeguro(status) {
      switch (status.toLowerCase()) {
        case "fail":
          return false;
        case "cancelado":
          return false;
        case "erro_maximo_tentativas":
          return false;
        case "solicitacao_cancelamento":
          return false;
        case "cancelamento_aprovado":
          return false;
        case "erro_dados":
          return false;
        case "nao_aprovado_no_antifraude":
          return false;
        case "expirado":
          return false;
        default:
          return true;
      }
    },
    verificarStatus(status) {
      switch (status.toLowerCase()) {
        case "success":
          return true;
        case "pago":
          return true;
        case "confirmado":
          return true;
        default:
          return false;
      }
    },
    cadastrarSegurado(carrinhoId, pacoteId, pacotes) {
      this.carrinhoId = carrinhoId;
      this.pacoteId = pacoteId;
      this.pacotes = pacotes;
      this.mostrarFormSegurado = true;
    },

    getProdutoInfo(pacoteId) {
      this.mostrarDetalhes = true;
      this.pacoteId = pacoteId;
      this.loadingDetalhes = true;
      const lojaService = new LojaService();

      lojaService.getOne(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              let seguros = body.data.loja;
              this.tipoFormulario = seguros[0].formulario;
              let seguro = seguros[0].produtos;

              // this.pacotes = this.segurosComprados[0].pacotes;
              this.tituloSeguro = seguro[0].produto;
              this.descricaoSeguro = seguro[0].prevencao;
              this.loadingDetalhes = false;
            } else if (status === 400 || status === 500) {
              this.$router.push({ name: "login" });
            }
          },
          onError: () => {},
          onEnd: () => {},
        },
        pacoteId
      );
    },

    fecharDetalhes() {
      this.mostrarDetalhes = false;
      this.descricaoSeguro = "";
      this.tituloSeguro = "";
    },
  },
};
</script>

<style scoped>
.container-carregando {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-seguro {
  display: flex;
  margin-left: 120px;
}

.container-info {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.seguro-info {
  /* background-color: red; */
  padding: 0.3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-info {
  /* background-color: red; */
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.2rem;
}

.container-botoes {
  display: flex;
  justify-content: space-between;
}

.container-detalhes {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}

.conteudo-detalhes {
  width: 80%;
  text-align: left;
}

.conteudo-beneficiario {
  width: 80%;
  display: flex;
  justify-content: space-between;
}

.container-botoes-externos {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.botoes-detalhes {
  display: flex;
}

.botao-cadastrar {
  border: 2px solid #3178ac;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.botao-info {
  /* all: unset;
    cursor: pointer; */
  background-color: #3178ac !important;
}

.botao-detalhe {
  background-color: #3178ac !important;
}

.botao-detalhe-titulo {
  color: #fff;
  text-transform: capitalize;
}

.botao-voltar {
  border: 2px solid #3178ac;
}

.botao-cancelar {
  background-color: #3178ac !important;
}

.nav-tabs .nav-link.active,
.nav-tabs:focus {
  border-color: transparent transparent #3178ac;
  border-bottom: 3px solid #3178ac;
  color: #3178ac;
  font-weight: 600;
}
.nav-link:hover,
.nav-tabs {
  border-color: transparent;
  color: #3178ac;
  font-weight: 600;
}
.titles {
  margin-left: 29px;
  font-size: 29px;
}
small {
  text-align: left;
  font-weight: 600;
  color: #757575;
}
.card-img-wrapper {
  max-width: 128px;
  border-radius: 5px;
}
.card-rel {
  display: flex;
}
h4,
p {
  text-align: left;
}

.detalhes-pedido {
  font-weight: bold;
  cursor: pointer;
  font-size: 14px;
}

.comprar-button {
  text-transform: unset;
  font-weight: bold;
}

@media (max-width: 600px) {
  .container-seguro {
    width: 100%;
    display: flex;
    margin-left: 0;
    padding: 20px;
    justify-content: center;
  }

  .content-info {
    /* background-color: red; */
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1.2rem;
  }

  .container-botoes {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .botoes-detalhes {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .botao-cadastrar {
    margin-bottom: 10px;
  }

  .botao-detalhe-titulo {
    /* align-self: stretch; */
  }

  .conteudo-beneficiario {
    align-items: center;
    flex-direction: column;
  }

  #botao-remover {
    margin-left: 0 !important;
    margin-top: 0.3rem;
  }
}
</style>
