<template>
  <div class="home">
    <div>
      <header-geral />
    </div>

    <div class="container mtsc-4">
      <v-card class="pt-4">
        <h3>Cartão de vacina</h3>
        <v-card-text>
          <div class="row container p-4">
            <div class="col-12" v-if="pessoas.length > 0">
              <v-card
                v-for="pessoa in pessoas"
                :key="pessoa.cpf"
                class="col-12 d-flex flex-column mb-4 rounded-t-lg p-2"
                elevation="0"
                outlined
              >
                <div
                  class="grey lighten-3 row rounded-t-lg"
                  style="padding: 0; text-align: left"
                >
                  <h4 class="col">{{ pessoa.nome }}</h4>
                </div>

                <div class="row p-2 d-flex align-content-right">
                  <v-row class="p-3" v-if="tickvacinas" v-html="getVacinas(cartao_vacinas[pessoa.cpf])">
                  </v-row>
                  <v-btn
                    @click="createCartaoDeVacinaPdf(pessoa.cpf)"
                    class="appBG-primary comprar-button white--text"
                  >
                    Gerar cartão de vacinas</v-btn
                  >
                </div>
              </v-card>
            </div>
            <h4 class="grey--text" v-else>Não há gesto vacinal cadastrado</h4>
          </div>
        </v-card-text>
      </v-card>
    </div>
    <div class="mt-16">
      <footer-app />
    </div>

    <img v-show="false" ref="imagePDF" src="@/assets/images/logo-saocarlos.png">
  </div>
</template>

<script>
import GestoVacinalService from "../services/gesto_vacinal_service";
import headerGeral from "@/components/template/header/header_geral.vue";
import footerApp from "@/components/template/footer_app.vue";
import 'jspdf';
import jsPDF from 'jspdf';

export default {
  components: {
    headerGeral,
    footerApp,
  },

  beforeMount() {
    if (!this.$store.state.token) {
      this.$router.push("/");
    }

    this.atualizaDados();
  },

  mounted() {
    this.atualizaDados();
  },

  methods: {
    createCartaoDeVacinaPdf(cpf) {
      const vacinas = this.cartao_vacinas[cpf];
      const doc = new jsPDF();
      const offsetX = 10;
      let offsetY = 10;

      const hash = (new Date()).getUTCMilliseconds()
      let imgData = this.$refs["imagePDF"];
      console.log(vacinas)
      doc.addImage(imgData, 'png', offsetX, offsetY, imgData.style.width, imgData.style.height);  

      doc.setFontSize(32)
      doc.setTextColor('#a92429')
      doc.setFont('serif')
      doc.text('Cartão de vacinas', 100, offsetY + 9)

      doc.setTextColor('#000')

      const pessoaCartao = this.pessoas.find(p => p.cpf == cpf); 

      offsetY += 30;

      doc.setFontSize(16)
      doc.text(`nome: ${pessoaCartao.nome}`, offsetX, offsetY)
      offsetY += 7;
      doc.text(`cpf: ${pessoaCartao.cpf_paciente}`, offsetX, offsetY)
      offsetY += 7;
      doc.text(`data de nascimento: ${pessoaCartao.data_nascimento.split('-').reverse().join('/')}`, offsetX, offsetY)

      offsetY += 25;
      doc.setTextColor('#a92429')
      doc.setFontSize(32)

      doc.text('Vacinas', offsetX, offsetY)

      doc.setFont('serif')
      doc.setTextColor('#000')
      
      offsetY += 10;

      doc.setFontSize(16)
      vacinas.forEach(vacina => {
        let text = vacina.data_aplicacao.split('-').reverse().join('/')
        text += ' - ' + vacina.vacina
        doc.text(text, offsetX, offsetY)
        offsetY += 7;
      });


      doc.save(`CARTAO_VACINA_SAO_CARLOS_${hash}.pdf`)
    },

    closeDialog() {
      this.dialog = false;
      this.atualizaDados();
    },

    getVacinas(vacinas) { 
      if (vacinas) {
        const getBrData = d => d.split('-').reverse().join('/')
        return vacinas.map(v => getBrData(v.data_aplicacao) + ' - ' + v.vacina).join("<br>")
      }
    },

    atualizaDados() {
      this.atualizaListaCpf();
    },

    gerarCartaoVacina(cpf) {
      const gvService = new GestoVacinalService();

      const onSucess = (status) => (body) => {
        if (status === 200) {
          this.cartao_vacinas[cpf] = body.data;
        } else {
          return;
        }
      };

      const onError = (error) => {
        console.log(error);
      };

      const onEnd = () => {
        this.tickvacinas++
      };

      gvService.getCartaoVacina({ onSucess, onError, onEnd }, cpf);
    },

    atualizaListaCpf() {
      const gvService = new GestoVacinalService();

      const onSucess = (status) => (body) => {
        if (status === 200) {
          this.pessoas = body.data.map(pessoa => {
            pessoa.cpf.replace('-', '').replace('.', '').replace('.', '')
            this.gerarCartaoVacina(pessoa.cpf)
            return pessoa
          });
        } else {
          return;
        }
      };

      const onError = (error) => {
        console.log(error);
      };

      const onEnd = () => {
        return; //
      };

      gvService.getListaCartao({ onSucess, onError, onEnd });
    },
  },

  data: () => ({
    pessoas: [],
    cartao_vacinas: {},
    tickvacinas: 0,
  }),
};
</script>

<style scoped>
.banner-principal {
  margin-top: 8rem;
}

.mtsc-4 {
  margin-top: 10rem;
}
</style>
