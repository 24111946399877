<template>
  <div class="home">
    <v-navigation-drawer v-model="abaFiltroMobile" fixed temporary width="60%">
      <botoes-filtro-mobile
        class="px-2 py-3"
        :filtro="filtro"
        @mudaFiltroProduto="mudaFiltroProdutos"
      />
    </v-navigation-drawer>
    <div>
      <header-geral />
    </div>
    <div class="mb-5">
      <banner-app />
    </div>
    <div class="mb-5">
      <pacote />
    </div>
    <div class="mb-5">
      <sobre-enseada />
    </div>
    <div class="mb-5" id="banner">
      <banner-pacotes />
    </div>
    <!--<div>
      <filtro-geral id="FiltroGeral" :key="filtro" v-bind:filtro="getFiltro" @mudaFiltroProduto="mudaFiltroProdutos" @abaFiltro="abaFiltroMobile = !abaFiltroMobile" />
    </div>
    <div class="mt-16">
      <calendario-vacinal />
    </div>
    <div class="mt-16">
      <servicos-especiais />
    </div>
    <div class="mt-16">
      <depoimentos />
    </div>
    <div class="mt-16">
      <painel-redes-sociais />
    </div>-->
    <div class="mt-16">
      <footer-app />
    </div>
  </div>
</template>

<script>
import headerGeral from "@/components/template/header/header_geral.vue";
import pacote from "@/components/template/pacote.vue";
import sobreEnseada from "@/components/template/sobre_enseada.vue";
import bannerPacotes from "@/components/home/banner_pacotes.vue";
import footerApp from "@/components/template/footer_app.vue";
import bannerApp from "@/components/template/banner_app.vue";

export default {
  components: {
    headerGeral,
    pacote,
    sobreEnseada,
    bannerPacotes,
    footerApp,
    bannerApp,
  },
  methods: {
    mudaFiltroProdutos(id) {
      this.filtro = id;
    },
  },
  data: () => ({
    abaFiltroMobile: null,
    dialog: false,
    filtro: "",
  }),
  computed: {
    getFiltro() {
      return this.filtro;
    },
  },
};
</script>

<style scoped>
.banner-principal {
  margin-top: 8rem;
}
</style>
