<template>
  <div>
    <div class="">
      <div class="total container">
        <Menu_loja />
      </div>
      <div class="bloco">
        <div class="col-5">
          <div class="text-left appBG-primary txt-banner">
            <span class="titles">Você seguro em tudo que precisar! </span>
            <p>
              Mais tranquilidade e segurança para você e sua família!
            </p>
            <v-btn class="btncall" href="../servicos">
              Conheça nossos seguros
            </v-btn>
          </div>
        </div>
        <div class="col-7">
          <div
            id="carouselExampleControls"
            class="carousel slide"
            data-bs-ride="carousel"
          >
            <div class="carousel-inner">
              <div class="carousel-caption d-none d-flex row">
                <div class="col-3"></div>
                <div class="col-9"></div>
                <!-- <div class="row menu-home">
                          <div class="it">
                            <v-icon>mdi-magnify</v-icon>
                            <input class="form-control" v-model="destino" placeholder="Para onde você vai?">
                          </div>
                          <div class="it">
                            <v-icon>mdi-map-marker-outline</v-icon>
                            <input class="form-control" v-model="destino" placeholder="O que está buscando?">
                          </div>
                          <div class="it">
                            <v-icon>mdi-calendar-blank-outline</v-icon>
                            <input class="form-control" v-model="destino" placeholder="Ida?">
                          </div>
                          <div class="it">
                            <v-icon>mdi-calendar-blank-outline</v-icon>
                            <input class="form-control" v-model="destino" placeholder="Volta?">
                          </div>
                        </div> -->
              </div>
              <div class="carousel-item active">
                <img
                  src="@/assets/images/happy-couple-driving-convertible-car-enjoying-summ-2022-03-15-06-00-23-utc.webp"
                  class="imgbanner w-100"
                  alt="..."
                />
              </div>
              <div class="carousel-item">
                <img
                  src="@/assets/images/happy-family-on-the-beach-2021-08-26-17-04-56-utc.webp"
                  class="imgbanner d-block w-100"
                  alt="..."
                />
              </div>
              <div class="carousel-item">
                <img
                  src="@/assets/images/cute-little-girl-hugging-grandfather-when-he-playi-2022-02-05-12-51-22-utc.webp"
                  class="imgbanner d-block w-100"
                  alt="..."
                />
              </div>
              <div class="carousel-item">
                <img
                  src="@/assets/images/portrait-of-happy-man-with-helmet-and-sunglasses-o-2021-08-26-23-05-31-utc.webp"
                  class="imgbanner d-block w-100"
                  alt="..."
                />
              </div>
            </div>
            <button
              class="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="prev"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
            </button>
            <button
              class="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="next"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import bgTur from "@/assets/images/banne2.png";
import Menu_loja from "./header/navbar/menu_loja.vue";
export default {
  components: {
    Menu_loja,
  },
  data() {
    return {
      bgTurismo: bgTur,
    };
  },

  methods: {},
};
</script>
<style scoped>
.total {
  width: 100%;
}
.imgbanner {
  height: 28rem;
}
.bannercall {
  text-align: left;
  background-size: cover;
  padding: 3rem 0rem 2rem 11rem;
}
.btncall {
  border: 3px solid #fff;
  background-color: transparent !important;
  font-weight: 500;
  padding: 1rem 2rem 1rem 2rem !important;
  color: #fff !important;
  text-transform: none;
  border-radius: 1px;
}
.menu-home .it {
  display: flex;
}
.menu-home {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  margin-top: 1rem;
}
.carousel-caption {
  top: 1rem;
}
.carousel-inner {
  background-color: #2b3a8f;
}
.txt-banner {
  padding: 13% 26% 0% 13%;
  height: 31rem;
  color: #fff;
}
.menu-home input {
  width: 100% !important;
}
.menu-home .v-icon {
  margin-top: 0rem;
  background-color: #e8e8e8;
  height: 2.3rem;
  width: 21%;
  margin-right: -0.5rem;
  border-radius: 6px 0px 0px 6px;
  color: #2b3a8f;
}
input {
  background-color: #e8e8e8 !important;
  height: 2.3rem;
}
::-webkit-input-placeholder {
  color: #2b3a8f;
  font-weight: 600;
}
.carousel {
  position: relative;
  margin-top: 1.5rem;
  margin-left: -9rem;
}
@media (max-width: 600px) {
  .carousel {
    width: auto;
    margin: -12rem 0rem -4rem 0rem !important;
  }
  .imgbanner {
    height: 15rem;
  }
  /* .carousel-inner {
    height: 28rem;
    width: 95%;
  } */
  /* .carousel-item {
    width: 83rem;
  }
  .carousel {
    margin-top: -14rem;
    width: 100%;
    margin-left: 0rem;
    margin-left: 0.5rem;
  }
  .carousel-caption {
    justify-content: center;
  } */
  .bannercall {
    text-align: center;
    background-size: cover;
    padding: 3rem 3rem 3rem 3rem;
  }
  .txt-banner {
    margin-top: 2rem;
    line-height: 21px;
    padding: 13% 26% 0% 8%;
  }
  .menu-home {
    margin-top: 0rem;
    justify-content: center;
  }
  .menu-home .it {
    margin: 1% 0% 1% 0%;
  }
}
</style>