import HTTPService from '@/services/http_service.js'

export default class CategoriaService extends HTTPService {
  resource = 'compras'
  cadastrar(responseFunctions, categoria) {
    this.request(responseFunctions, 'POST', this.resource, categoria)
  }

  // getExames (responseFunctions, nome) {
  //     let imprevisto = nome ?? ''
  //     if (imprevisto === '') {
  //         this.request(responseFunctions, 'GET', this.resource, null)
  //     } else {
  //         this.request(responseFunctions, 'GET', this.resource, null, nome)
  //     }
  // }

  getList(responseFunctions) {
    this.request(responseFunctions, 'GET', this.resource, null)
  }

  getAll({ onSucess, onError, onEnd }) {
    const responseFunctions = {
      onSucess,
      onError,
      onEnd
    }
    // let queryparams ={
    //   page,
    //   per_page,
    //   cliente,
    // }

    this.request(responseFunctions, 'GET', this.resource, null)
  }

  getOne({ onSucess, onError, onEnd }, compra) {
    const responseFunctions = {
      onSucess,
      onError,
      onEnd
    }
    // let queryparams ={
    //   page,
    //   per_page,
    //   compra,
    // }

    this.request(responseFunctions, 'GET', `${this.resource}/${compra}`, null)
  }


  comprar({ onSucess, onError, onEnd }, compra, meioPag) {
    const responseFunctions = {
      onSucess,
      onError,
      onEnd
    }

    this.jsonRequest(responseFunctions, 'POST', `pag${meioPag}`, compra)
  }
}