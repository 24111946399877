import HTTPService from '@/services/http_service.js'

export default class UsuarioService extends HTTPService {
    resource = 'cliente'

    register(responseFunctions, usuario) {
        this.request(responseFunctions, 'POST', this.resource, usuario)
    }

    alterarSenha(responseFunctions, senhas, idUsuario) {
        this.request(responseFunctions, 'POST', this.resource + '/' + idUsuario + '/alterar-senha', senhas)
    }

    esqueciSenha(responseFunctions, email) {
        this.request(responseFunctions, 'POST', 'auth/' + this.resource + '/esqueci-a-senha', email)
    }

    getUsuarioData({ onSucess, onError, onEnd }, idUsuario) {
        const url = `${this.resource}/${idUsuario}`;
        this.request({ onSucess, onError, onEnd }, 'GET', url)
    }

    updateUsuarioData({ onSucess, onError, onEnd }, idUsuario, data) {
        const url = `${this.resource}/${idUsuario}`;
        this.request({ onSucess, onError, onEnd }, 'POST', url, data)
    }

    updateUsuarioSenha({ onSucess, onError, onEnd }, idUsuario, data) {
        const url = `auth/mudar-senha/${idUsuario}`;
        this.request({ onSucess, onError, onEnd }, 'POST', url, data)
    }

    cadastrarSegurado({ onSucess, onError, onEnd }, data) {
        const url = `segurado`
        this.request({ onSucess, onError, onEnd }, 'POST', url, data)
    }

    retornarTotalSeguradosCadastrados({ onSucess, onError, onEnd }, idCarrinho) {
        const url = `segurado/totalcadastrados/carrinho/${idCarrinho}`
        this.request({ onSucess, onError, onEnd }, 'GET', url)
    }

    getList(onSucess, onError, onEnd, page, per_page, filter, order, desc) {
        const responseFunctions = {
            onSucess,
            onError,
            onEnd
        }
        let queryparams = {
            page: page,
            per_page: per_page
        }
        if (filter === '') {
            if (order) {
                let queryparams = {
                    page: page,
                    per_page: per_page,
                    sort: desc ? 'nome' : '-nome'
                }
                this.request(responseFunctions, 'GET', this.resource, null, queryparams)
            } else {
                this.request(responseFunctions, 'GET', this.resource, null, queryparams)
            }
        } else {
            let queryparams = {
                page: page,
                per_page: per_page,
                nome: filter,
            }
            if (order) {
                let queryparams = {
                    page: page,
                    per_page: per_page,
                    nome: filter,
                    sort: desc ? 'nome' : '-nome'
                }
                this.request(responseFunctions, 'GET', this.resource, null, queryparams)
            } else {
                this.request(responseFunctions, 'GET', this.resource, null, queryparams)
            }
        }
    }
}